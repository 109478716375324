import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

import NewModal from 'components/NewModal';

import classes from './OverridingUserUpdateModal.module.scss';

const OverridingUserUpdateModal = ({
  isOpen,
  toggle,
  loading,
  onSubmit,
  lastUpdatedBy,
  lastUpdatedAt,
  isDeployment = false,
}) => {
  const { firstName, lastName, email } = lastUpdatedBy || {};

  const formattedDate = lastUpdatedAt?.toDate
    ? format(lastUpdatedAt.toDate(), 'PPpp')
    : '';

  if (!lastUpdatedBy) {
    return null;
  }

  return (
    <NewModal
      isOpen={isOpen}
      toggle={loading ? null : toggle}
      title="VERSION UPDATE WARNING"
      showSubmitButton
      submitButtonProps={{
        onSubmit,
        canSubmit: true,
        loading,
        buttonText: 'Continue',
      }}
    >
      <div className={classes.container}>
        <p>
          {isDeployment && 'Deployment creation also updates current version.'}
          <br />
          You might be overriding another user&apos;s updates. Do you want to
          continue?
        </p>
        <div>
          <h3>Last updated by</h3>
          <p>
            {firstName} {lastName} At {formattedDate}
          </p>

          <h3>Contact Info:</h3>
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    </NewModal>
  );
};

OverridingUserUpdateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  lastUpdatedBy: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  lastUpdatedAt: PropTypes.instanceOf(Timestamp),
};

export default OverridingUserUpdateModal;
