import React, { useEffect, useCallback, useMemo } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import {
  REACT_APP_ALGOLIA_SURVEYS_CREATED_AT_ASC_INDEX,
  REACT_APP_ALGOLIA_SURVEYS_CREATED_AT_DESC_INDEX,
  REACT_APP_ALGOLIA_SURVEYS_INDEX,
  REACT_APP_ALGOLIA_SURVEYS_TITLE_ASC_INDEX,
  REACT_APP_ALGOLIA_SURVEYS_TITLE_DESC_INDEX,
} from 'constants/environment';
import {
  selectDeleteVersionState,
  selectSurveysByTypeState,
} from 'state/selectors/surveys';
import { triggerAnalyticsProcessing } from 'state/actions/analytics';
import {
  createOrganizationSurveyFromTemplate,
  surveysClearState,
  deleteDeployment,
} from 'state/actions/surveys';
import { deleteSurvey } from 'state/actions/surveys/delete/deleteSurvey';
import paths from 'pages/Router/paths';
import SurveyDetails from 'components/SurveyDetails';
import {
  validSurveyType,
  surveysPageTitle,
  addSurveyPath,
} from 'utils/surveyBuilder';
import { SurveyType } from 'utils/surveyBuilder/enums';
import { ModalType } from 'utils/enums';
import { initAlgoliaClient } from 'utils';
import useSurveyTable from 'hooks/useSurveysTable';
import useModal from 'hooks/useModal';
import { useAlgoliaSearch } from 'hooks';
import {
  Table,
  SearchBox,
  Filter,
  FilterOption,
  SortBy,
  Menu,
} from 'components/Algolia';
import SurveyFromTemplateModal from 'components/SurveyFromTemplateModal';
import Modal from 'components/Modal';

import classes from './Surveys.module.scss';
import './Surveys.scss';

const searchClient = initAlgoliaClient();
const index = REACT_APP_ALGOLIA_SURVEYS_INDEX;
const indexCreatedAtAsc = REACT_APP_ALGOLIA_SURVEYS_CREATED_AT_ASC_INDEX;
const indexCreatedAtDesc = REACT_APP_ALGOLIA_SURVEYS_CREATED_AT_DESC_INDEX;
const indexTitleAsc = REACT_APP_ALGOLIA_SURVEYS_TITLE_ASC_INDEX;
const indexTitleDesc = REACT_APP_ALGOLIA_SURVEYS_TITLE_DESC_INDEX;

const Surveys = () => {
  const { type } = useParams();

  const dispatch = useDispatch();

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const { search, refresh } = useAlgoliaSearch();

  const onUseVersionHandler = useCallback(
    ({ surveyName, surveyId, versionId, versionName, languageCompletion }) =>
      onOpenModalHandler(ModalType.CREATE_SURVEY_FROM_TEMPLATE, {
        surveyName,
        surveyId,
        versionId,
        versionName,
        languageCompletion,
      }),
    [onOpenModalHandler]
  );

  const isValidType = useMemo(() => validSurveyType(type), [type]);

  const onDeleteSurveyHandler = useCallback(({ surveyId }) => {
    onOpenModalHandler(ModalType.DELETE_SURVEY, {
      surveyId,
    });
  }, []);

  const onConfirmationDeleteSurveyHandler = useCallback(() => {
    const { surveyId } = modal;

    dispatch(deleteSurvey({ surveyId }));
  }, [modal, dispatch]);

  const { loading: deletingSurvey, success: successDeletingSurvey } =
    useSelector(selectDeleteVersionState);

  const { columnClicked, skipPageResetRef, columns, resetColumnPreferences } =
    useSurveyTable({
      surveyType: type,
      onUseVersionHandler,
      onDeleteSurveyHandler,
    });

  const {
    isAdminUser,
    defaultOrganization,
    creatingFromTemplate,
    createdFromTemplate,
    loading,
    deletedDeployment,
    error,
  } = useSelector(selectSurveysByTypeState, shallowEqual);

  useEffect(() => {
    if (successDeletingSurvey) {
      refresh();
      dispatch(surveysClearState());
    }
  }, [successDeletingSurvey, refresh, dispatch]);

  useEffect(() => {
    if (deletedDeployment || successDeletingSurvey) {
      refresh();
      resetColumnPreferences();
      onCloseModalHandler();
      dispatch(surveysClearState());
    }
  }, [
    refresh,
    deletedDeployment,
    onCloseModalHandler,
    resetColumnPreferences,
    dispatch,
  ]);

  useEffect(() => {
    if (type) {
      resetColumnPreferences();
    }
  }, [type, resetColumnPreferences]);

  useEffect(() => {
    return () => dispatch(surveysClearState());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      onCloseModalHandler();
      dispatch(surveysClearState());
    }
  }, [error, onCloseModalHandler, dispatch]);

  const onDeleteVersionHandler = useCallback(() => {
    // TODO
  }, []);

  const onDeleteDeploymentHandler = useCallback((deploymentId, surveyId) => {
    onOpenModalHandler(ModalType.DELETE_SURVEY_DEPLOYMENT, {
      deploymentId,
      surveyId,
    });
  }, []);

  const onConfirmationDeleteDeploymentModalHandler = useCallback(() => {
    const { deploymentId, surveyId } = modal;
    dispatch(deleteDeployment({ deploymentId, surveyId }));
  }, [modal, deleteDeployment, dispatch]);

  const onTriggerAnalyticsHandler = (deploymentData) => {
    dispatch(triggerAnalyticsProcessing(deploymentData));
  };

  const onCreateSurveyHandler = ({
    surveyName,
    versionName,
    organizationId,
    organizationName,
    organizationDisplaySiteName,
  }) => {
    const { surveyId, versionId, languageCompletion } = modal;
    dispatch(
      createOrganizationSurveyFromTemplate({
        surveyName,
        versionName,
        organizationId,
        organizationName,
        organizationDisplaySiteName,
        surveyId,
        versionId,
        languageCompletion,
      })
    );
  };

  const redirectToHome = useMemo(
    () => (!isValidType || !isAdminUser) && <Redirect to={paths.ROOT} />,
    [isValidType, isAdminUser]
  );

  const redirectCreatedFromTemplate = useMemo(
    () =>
      createdFromTemplate && (
        <Redirect
          to={`${paths.SURVEYS}/${createdFromTemplate.surveyId}/versions/${createdFromTemplate.versionId}`}
        />
      ),
    [createdFromTemplate]
  );

  const filters = useMemo(() => `type:'${type}'`, [type]);

  return (
    <>
      {modal.type === ModalType.CREATE_SURVEY_FROM_TEMPLATE && (
        <SurveyFromTemplateModal
          surveyName={modal.surveyName}
          versionName={modal.versionName}
          creatingSurvey={creatingFromTemplate}
          onCreateSurveyHandler={onCreateSurveyHandler}
          onCancelHandler={onCloseModalHandler}
        />
      )}
      {modal.type === ModalType.DELETE_SURVEY_DEPLOYMENT && (
        <Modal
          isActive
          isLoading={loading}
          confirmButtonMessage="Delete"
          title="Confirm action"
          body="This will permanently delete the deployment. Action cannot be undone."
          cancelButtonMessage="Cancel"
          onConfirmation={onConfirmationDeleteDeploymentModalHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {modal.type === ModalType.DELETE_SURVEY && (
        <Modal
          isActive
          isLoading={deletingSurvey}
          confirmButtonMessage="Delete"
          title="Confirm action"
          body="This will permanently delete the survey, the related versions and deployments. This action cannot be undone."
          cancelButtonMessage="Cancel"
          onConfirmation={onConfirmationDeleteSurveyHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {redirectToHome}
      {redirectCreatedFromTemplate}
      <InstantSearch
        searchClient={searchClient}
        indexName={index}
        refresh={search.refresh}
      >
        <Configure filters={filters} />
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">{surveysPageTitle(type)}</h1>
                </div>
              </div>
              <div className="level-right">
                <Link to={addSurveyPath(type)} className="button">
                  New Survey
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-main-section">
          <Filter>
            {type === SurveyType.ORGANIZATION && (
              <FilterOption label="Organization">
                <Menu
                  predefinedRefinement={defaultOrganization?.value.displayName}
                  predefinedOption={defaultOrganization}
                  attribute="organizationName"
                  emptyPlaceholder="No organizations"
                  defaultPlaceholder="Select an organization"
                  isSearchable
                  isClearable
                  limit={20}
                />
              </FilterOption>
            )}
            <FilterOption label="Sort by">
              <SortBy
                defaultRefinement={indexCreatedAtDesc}
                items={[
                  { value: index, label: 'Featured' },
                  { value: indexTitleAsc, label: 'Title asc.' },
                  { value: indexTitleDesc, label: 'Title desc.' },
                  { value: indexCreatedAtAsc, label: 'Date asc.' },
                  { value: indexCreatedAtDesc, label: 'Date desc.' },
                ]}
                defaultValue={4}
              />
            </FilterOption>
          </Filter>
          <div className="card has-table has-mobile-sort-spaced">
            <header className="card-header">
              <p className="card-header-title table-header">
                <span>Search</span>
                <SearchBox />
              </p>
            </header>
            <Table
              columns={columns}
              className={classes.table}
              renderRowSubComponent={({ row }) => (
                <SurveyDetails
                  index={row.index}
                  surveyName={row.original.name}
                  surveyId={row.original.objectID}
                  surveyType={type}
                  versions={row.original.versions}
                  deployments={row.original.deployments}
                  columnClicked={columnClicked}
                  onUseVersion={onUseVersionHandler}
                  onDeleteVersion={onDeleteVersionHandler}
                  onDeleteDeployment={onDeleteDeploymentHandler}
                  onTriggerAnalytics={onTriggerAnalyticsHandler}
                />
              )}
              skipPageResetRef={skipPageResetRef}
              resetColumnPreferences={resetColumnPreferences}
              defaultPerPageOption={10}
            />
          </div>
        </section>
      </InstantSearch>
    </>
  );
};

export default Surveys;
