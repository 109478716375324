import { toastr } from 'react-redux-toastr';
import { saveErrorLog } from 'services/utils';

import {
  SURVEYS_FETCH_DATA_FAIL,
  SURVEYS_FETCH_DATA_INIT,
  SURVEYS_FETCH_DATA_SUCCESS,
} from 'state/actionCreators/surveys';
import { firebaseError } from 'utils';
import {
  Collections,
  ServiceOperations,
  ServiceSubOperations,
} from 'utils/enums';
import { collection } from 'utils/firebase';
import {
  questionChoicesCollection,
  surveyDeploymentsCollection,
  surveyVersionsCollection,
  tagsCollection,
} from 'utils/firebase/surveys';
import { SurveyElement, SurveyTypes } from 'utils/surveyBuilder/enums';
import { updateSurveyLastOpened } from '../../update/updateSurveyLastOpened';

export const fetchSurvey = (
  surveyId,
  surveySelectedTypeId,
  surveyType,
  {
    deploymentIndex: deploymentIndexToUpdate,
    versionIndex: versionIndexToUpdate,
  }
) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_FETCH_DATA_INIT());

    const { locale } = getState().preferences;

    const surveyRef = collection(Collections.SURVEYS).doc(surveyId);
    const versionRef =
      (surveyType === SurveyTypes.VERSIONS &&
        surveyVersionsCollection(surveyId).doc(surveySelectedTypeId)) ||
      (surveyType === SurveyTypes.DEPLOYMENTS &&
        surveyDeploymentsCollection(surveyId).doc(surveySelectedTypeId));

    const surveys = [];
    let survey = null;

    try {
      const fetchSurveyTask = surveyRef.get();
      const fetchSurveySelectedTypeTask = versionRef.get();

      const [surveyDoc, surveySelectedTypeDoc] = await Promise.all([
        fetchSurveyTask,
        fetchSurveySelectedTypeTask,
      ]);

      if (!surveyDoc.exists || !surveySelectedTypeDoc.exists) {
        const errorMessage = 'Invalid survey or survey version id.';
        toastr.error('', errorMessage);
        return dispatch(
          SURVEYS_FETCH_DATA_FAIL({
            error: errorMessage,
          })
        );
      }

      survey = { id: surveyDoc.id, ...surveyDoc.data() };
      const fetchedSurvey = {
        id: surveySelectedTypeDoc.id,
        ...surveySelectedTypeDoc.data(),
      };

      const itemsWithTagsOrDataSet = Object.values(fetchedSurvey.items).filter(
        (item) =>
          (item.type === SurveyElement.QUESTION &&
            (item.question.tags.length !== 0 || item.question.dataSet.id)) ||
          (item.type === SurveyElement.QUESTION_GROUP &&
            item.questionGroup.dataSet.id)
      );

      const tags = {};
      const dataSetChoices = {};

      const fetchTagTasks = [];
      const fetchChoiceTasks = [];

      itemsWithTagsOrDataSet.forEach((item) => {
        const dataSetId =
          item.question?.dataSet.id || item.questionGroup?.dataSet.id;

        if (item.type === SurveyElement.QUESTION) {
          item.question.tags.forEach((tag) => {
            if (!tags[tag.id]) {
              tags[tag.id] = true;
              fetchTagTasks.push(tagsCollection.doc(tag.id).get());
            }
          });
        }

        if (dataSetId && !dataSetChoices[dataSetId]) {
          dataSetChoices[dataSetId] = [];
          fetchChoiceTasks.push(
            questionChoicesCollection
              .where('dataSetId', '==', dataSetId)
              .orderBy('sortOrder', 'asc')
              .get()
          );
        }
      });

      const fetchedTags = await Promise.all(fetchTagTasks);
      const fetchedDataSetChoices = await Promise.all(fetchChoiceTasks);

      fetchedDataSetChoices.forEach((choiceDocs) => {
        choiceDocs.forEach((choiceDoc) => {
          const choice = choiceDoc.data();

          dataSetChoices[choice.dataSetId].push({
            id: choiceDoc.id,
            ...choice,
          });
        });
      });

      fetchedTags.forEach((tagDoc) => {
        const tag = tagDoc.data();
        tags[tagDoc.id] = {
          id: tagDoc.id,
          ...tag,
          createdAt: tag.createdAt.toDate(),
        };
      });

      itemsWithTagsOrDataSet.forEach((item) => {
        const { question, questionGroup, type: itemType } = item;

        if (itemType === SurveyElement.QUESTION) {
          const updatedTags = [];

          question.tags.forEach((tag) =>
            updatedTags.push({
              ...tag,
              ...tags[tag.id],
            })
          );

          question.tags = updatedTags;
        }

        if (itemType === SurveyElement.QUESTION_GROUP) {
          questionGroup.questions.forEach((questionGroupQuestion, index) => {
            const { tags: questionTags } = questionGroupQuestion;
            const updatedTags = [];

            questionTags.forEach((tag) =>
              updatedTags.push({
                ...tag,
                ...tags[tag.id],
              })
            );

            questionGroup.questions[index].tags = updatedTags;
          });
        }

        const dataSetId = question?.dataSet.id || questionGroup?.dataSet.id;

        if (dataSetId) {
          if (itemType === SurveyElement.QUESTION) {
            const choices = question?.choices.map(
              ({ visible, notApplicable }, index) => {
                const choice = dataSetChoices[dataSetId][index];
                return { ...choice, visible, notApplicable };
              }
            );

            question.choices = [...choices];
            fetchedSurvey.items[item.id] = { ...item, question };
          }

          if (itemType === SurveyElement.QUESTION_GROUP) {
            const choices = questionGroup?.choices.map(
              ({ visible, notApplicable }, index) => {
                const choice = dataSetChoices[dataSetId][index];
                return { ...choice, visible, notApplicable };
              }
            );

            questionGroup.choices = [...choices];
            fetchedSurvey.items[item.id] = { ...item, questionGroup };
          }
        }
      });

      const commonData = {
        surveyId: survey.id,
        type: survey.type,
        versionCount: survey.versionCount,
        versions: survey.versions,
        deploymentCount: survey.deploymentCount,
        deployments: survey.deployments,
        organizationId: survey.organizationId,
        organizationName: survey.organizationName,
        organizationDisplaySiteName: survey.organizationDisplaySiteName,
        disableDeselect: !!fetchedSurvey.disableDeselect,
        pages: fetchedSurvey.pages,
        sections: fetchedSurvey.sections,
        items: fetchedSurvey.items,
        pagesOrder: fetchedSurvey.pagesOrder,
        versionTitle: fetchedSurvey.title,
        actions: fetchedSurvey.actions,
        availableLanguages: fetchedSurvey.availableLanguages,
        defaultLanguage: fetchedSurvey.defaultLanguage,
        isCondensed: fetchedSurvey.isCondensed,
      };

      if (surveyType === SurveyTypes.VERSIONS) {
        surveys.push({
          ...commonData,
          name: survey.name,
          surveyVersionId: fetchedSurvey.id,
          versionName: fetchedSurvey.name,
        });
      }

      if (surveyType === SurveyTypes.DEPLOYMENTS) {
        surveys.push({
          ...commonData,
          name: fetchedSurvey.surveyName,
          surveyDeploymentId: fetchedSurvey.id,
          versionName: fetchedSurvey.versionName,
          versionId: fetchedSurvey.versionId,
          startDate: fetchedSurvey.startDate,
          endDate: fetchedSurvey.endDate,
          waveId: fetchedSurvey.waveId,
          waveName: fetchedSurvey.waveName,
        });
      }
    } catch (error) {
      await saveErrorLog(error, {
        operation: ServiceOperations.FETCH_SURVEY,
        subOperation:
          ServiceSubOperations[ServiceOperations.FETCH_SURVEY].FETCH_SURVEY,
        surveyId: surveyId ?? null,
      });

      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SURVEYS_FETCH_DATA_FAIL({
          error: errorMessage,
        })
      );
    }

    try {
      const deploymentIndex = Number(deploymentIndexToUpdate);
      const versionIndex = Number(versionIndexToUpdate);

      if (deploymentIndex || versionIndex) {
        await updateSurveyLastOpened({
          deploymentIndex,
          versionIndex,
          surveyRef,
          userData: getState().auth.userData,
          survey,
          versions: survey.versions,
          deployments: survey.deployments,
        });
      }
    } catch (error) {
      console.log('error', error);

      await saveErrorLog(error, {
        operation: ServiceOperations.FETCH_SURVEY,
        subOperation:
          ServiceSubOperations[ServiceOperations.FETCH_SURVEY]
            .UPDATE_LAST_OPENED,
        surveyId: surveyId ?? null,
      });

      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SURVEYS_FETCH_DATA_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(SURVEYS_FETCH_DATA_SUCCESS({ surveys }));
  };
};
