import firebase from 'firebase.js';

const generateLastUpdatedData = (survey, userData) => ({
  ...survey,
  lastUpdatedAt: firebase.firestore.Timestamp.now(),
  lastUpdatedBy: {
    id: userData.id,
    email: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
    logoUrl: userData.logoUrl,
  },
});

export const updateSurveyLastUpdated = ({
  deploymentIndex = null,
  versionIndex = null,
  surveyRef,
  userData,
  versions = [],
  deployments = [],
}) => {
  if (Number.isInteger(deploymentIndex)) {
    return surveyRef.update({
      deployments: deployments.map((deployment, index) =>
        index === deploymentIndex
          ? generateLastUpdatedData(deployment, userData)
          : deployment
      ),
    });
  }

  if (Number.isInteger(versionIndex)) {
    return surveyRef.update({
      versions: versions.map((version, index) =>
        index === versionIndex
          ? generateLastUpdatedData(version, userData)
          : version
      ),
    });
  }
  return null;
};
