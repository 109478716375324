import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { authReducer } from './auth';
import { usersReducer } from './users';
import { preferencesReducer } from './preferences';
import { organizationsReducer } from './organizations';
import { groupsReducer } from './groups';
import { tagsReducer } from './tags';
import { dataSetsReducer } from './dataSets';
import { wavesReducer } from './waves';
import { surveyReducer } from './surveys';
import { questionChoicesReducer } from './questionChoices';
import { analyticsReducer } from './analytics';
import { industriesReducer } from './industries';
import { tasksReducer } from './tasks';
import { reprocessResponsesReducer } from './reprocessResponses';
import { sectionsSavesReducer } from './sectionsSaves';
import { liveSurveyReducer } from './liveSurvey';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    {
      key: 'preferences',
      storage,
      blacklist: ['error', 'loading'],
    },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  organizations: persistReducer(
    {
      key: 'organizations',
      storage,
      blacklist: ['error', 'loading'],
    },
    organizationsReducer
  ),
  industries: persistReducer(
    {
      key: 'industries',
      storage,
      blacklist: ['error', 'loading'],
    },
    industriesReducer
  ),
  tags: persistReducer(
    {
      key: 'tags',
      storage,
      blacklist: [
        'error',
        'loading',
        'success',
        'deleting',
        'deleted',
        'fetchingTags',
        'successFetchingTags',
        'errorFetchingTags',
        'fetchingRelatedData',
        'errorFetchingRelatedData',
        'relatedData',
        'creatingTag',
        'successCreatingTag',
        'errorCreatingTag',
        'createdTag',
      ],
    },
    tagsReducer
  ),
  groups: persistReducer(
    {
      key: 'tags',
      storage,
      blacklist: ['error', 'loading'],
    },
    groupsReducer
  ),
  dataSets: persistReducer(
    {
      key: 'dataSets',
      storage,
      blacklist: ['error', 'loading'],
    },
    dataSetsReducer
  ),
  waves: persistReducer(
    {
      key: 'waves',
      storage,
      blacklist: ['error', 'loading'],
    },
    wavesReducer
  ),
  surveys: persistReducer(
    {
      key: 'surveys',
      storage,
      blacklist: [
        'deployment',
        'loading',
        'error',
        'errorFetching',
        'success',
        'updated',
        'fetched',
        'creatingVersion',
        'selectedVersion',
        'creatingDeployment',
        'createdDeployment',
        'creatingFromTemplate',
        'createdFromTemplate',
        'creatingResponse',
        'createdResponse',
      ],
    },
    surveyReducer
  ),
  questionChoices: persistReducer(
    {
      key: 'questionChoices',
      storage,
      blacklist: ['fetchedQuestionChoices', 'error', 'loading'],
    },
    questionChoicesReducer
  ),
  analytics: persistReducer(
    {
      key: 'analytics',
      storage,
      blacklist: ['error', 'loading', 'success'],
    },
    analyticsReducer
  ),
  tasks: persistReducer(
    {
      key: 'tasks',
      storage,
      blacklist: ['error', 'loading', 'success'],
    },
    tasksReducer
  ),
  reprocessResponses: persistReducer(
    {
      key: 'reprocessResponses',
      storage,
      blacklist: ['error', 'loading', 'success'],
    },
    reprocessResponsesReducer
  ),
  sectionsSaves: persistReducer(
    {
      key: 'sectionsSaves',
      storage,
      blacklist: [
        'createSectionLoading',
        'createSectionsuccess',
        'createSectionError',
      ],
    },
    sectionsSavesReducer
  ),
  liveSurvey: persistReducer(
    {
      key: 'liveSurvey',
      storage,
      blacklist: ['error', 'loading', 'success', 'questionResponses', 'pagesWithActions', 'pageIndex', 'organizationName', 'response', 'isPreview'],
    },
    liveSurveyReducer
  ),
  toastr: toastrReducer,
});
