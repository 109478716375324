import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Spinner from 'react-spinners/ClipLoader';

import { colors } from 'styles';
import {
  selectCreateResponseInProgressState,
  selectFetchResponseInProgressState,
} from 'state/selectors/surveys';
import { setUserLocale } from 'state/actions/preferences';
import useNewModal from 'hooks/useNewModal';
import NewModal from 'components/NewModal';

import classes from './Page.module.scss';

const SaveLoadTitle = () => (
  <>
    <span className="icon">
      <i className="mdi mdi-file-arrow-up-down-outline" />
    </span>
    Save/Load Response
  </>
);

const Page = ({
  title,
  subtitle,
  children,
  isSampleSurvey,
  selectedLanguage,
  availableLanguages,
  setSurveyLanguage,
  languageCompletion,
  defaultLanguage,
  onSaveResponse,
  onLoadResponse,
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { toggle, isOpen } = useNewModal();
  const [languageOptions, setLanguageOptions] = useState([]);
  const [responseCode, setResponseCode] = useState('');

  const paths = pathname.split('/') || [];
  const isPreview = (paths[paths.length - 1] || paths[paths.length - 2]) === 'preview';

  const {
    loading: fetchingResponseSave,
    success: successFetchingResponseSave,
  } = useSelector(selectFetchResponseInProgressState);

  const {
    responseCode: createdResponseCode,
    loading: creatingResponseSave,
    success: successCreatingResponseSave,
  } = useSelector(selectCreateResponseInProgressState);

  const availableLanguagesMemo = useMemo(
    () => JSON.stringify(availableLanguages),
    [availableLanguages]
  );

  useEffect(() => {
    const options = [];

    if (availableLanguages) {
      availableLanguages.forEach(({ code, originalName }) => {
        const isLanguageComplete = languageCompletion[code] === 100;

        if (isLanguageComplete || code === defaultLanguage) {
          options.push({
            label: originalName,
            value: code,
          });
        }
      });
    }

    setLanguageOptions(options);
  }, [languageCompletion, defaultLanguage, availableLanguagesMemo]);

  useEffect(() => {
    if (!successCreatingResponseSave) {
      setResponseCode('');
    }
  }, [successCreatingResponseSave]);

  useEffect(() => {
    if (successFetchingResponseSave) {
      setResponseCode('');
      toggle();
    }
  }, [successFetchingResponseSave]);

  const onLoadResponseHandler = () => {
    onLoadResponse(responseCode);
  };

  const onChangeResponseCodeHandler = ({ target: { value } }) =>
    setResponseCode(value);

  const onChangeLanguageHandler = (select) => {
    const languageCode = select.value.toLowerCase().replace('-', '');

    dispatch(setUserLocale(languageCode));
    setSurveyLanguage((prevState) => ({
      ...prevState,
      code: select.value,
      select,
    }));
  };

  const loading = creatingResponseSave || fetchingResponseSave;

  return (
    <>
      <NewModal
        title={<SaveLoadTitle />}
        isOpen={isOpen}
        toggle={loading ? null : toggle}
      >
        <div className="field">
          <span className="label">Save Response:</span>
          <div className={classes['save-response-content']}>
            <button
              className="button is-info"
              onClick={onSaveResponse}
              disabled={loading}
            >
              {creatingResponseSave ? (
                <Spinner size="18" color={colors.white} />
              ) : (
                'Save'
              )}
            </button>
            {successCreatingResponseSave && (
              <span className="label">
                Response Code:&nbsp;
                <span className={classes['response-code']}>
                  {createdResponseCode}
                </span>
              </span>
            )}
          </div>
        </div>
        <div className="field">
          <span className="label">Load Response:</span>
          <div className={classes['load-section']}>
            <input
              className="input"
              value={responseCode}
              onChange={onChangeResponseCodeHandler}
              disabled={loading}
            />
            <button
              className="button is-info"
              onClick={onLoadResponseHandler}
              disabled={loading}
            >
              {fetchingResponseSave ? (
                <Spinner size="18" color={colors.white} />
              ) : (
                'Load'
              )}
            </button>
          </div>
        </div>
      </NewModal>
      <div className={classNames('container', classes.page)}>
        <div className={classes['page-header-container']}>
          <div className={classes['page-title']}>
            <div className={classNames('level-item', classes['w-100'])}>
              {title && (
                <h1 id="title" className="title is-2">
                  {title}
                </h1>
              )}
            </div>
          </div>

          <div className={classNames(classes['page-header-right'])}>
            {isSampleSurvey && (
              <span className={classNames("tag is-warning is-large", classes["sample-survey-tag"])}>Sample Survey</span>
            )}
            <div
              className={classNames(
                'level-item',
                classes['language-and-save-container']
              )}
            >
              {availableLanguages && (
                <div style={{ width: '100%' }}>
                  <Select
                    styles={{
                      menu: (base) => ({ ...base, zIndex: 2 }),
                    }}
                    value={selectedLanguage}
                    options={languageOptions}
                    onChange={onChangeLanguageHandler}
                  />
                </div>
              )}
              {availableLanguages && !isPreview && (
                <button
                  onClick={toggle}
                  type="button"
                  className="button is-info"
                >
                  <span className="icon">
                    <i className="mdi mdi-file-arrow-up-down-outline mdi-24px" />
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
        {subtitle && (
          <h2 id="subtitle" className="title is-3 is-spaced">
            {subtitle}
          </h2>
        )}
        <hr />
        <div id="children-placeholder">{children}</div>
      </div>
    </>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isSampleSurvey: PropTypes.bool,
  selectedLanguage: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  availableLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ),
  onSaveResponse: PropTypes.func.isRequired,
  onLoadResponse: PropTypes.func.isRequired,
  setSurveyLanguage: PropTypes.func,
};

Page.defaultProps = {
  title: '',
  subtitle: '',
  isSampleSurvey: false,
  selectedLanguage: null,
  availableLanguages: null,
  setSurveyLanguage: null,
};

export default Page;
