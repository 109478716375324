import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import { firebaseError } from 'utils';
import { CallableFunctions } from 'utils/enums';

export const ANALYTICS_TRIGGER_PROCESSING_INIT = createAction(
  'ANALYTICS_TRIGGER_PROCESSING_INIT'
);
export const ANALYTICS_TRIGGER_PROCESSING_SUCCESS = createAction(
  'ANALYTICS_TRIGGER_PROCESSING_SUCCESS'
);
export const ANALYTICS_TRIGGER_PROCESSING_FAIL = createAction(
  'ANALYTICS_TRIGGER_PROCESSING_FAIL'
);

export const ANALYTICS_CLEAR_STATE = createAction('ANALYTICS_CLEAR_STATE');

export const triggerAnalyticsProcessing = ({
  surveyId,
  deploymentId,
  waveId,
}) => {
  return async (dispatch, getState) => {
    dispatch(ANALYTICS_TRIGGER_PROCESSING_INIT({ deploymentId }));

    toastr.info('', 'Notifying analytics');

    const { locale } = getState().preferences;

    const triggerAnalytics = firebase
      .functions()
      .httpsCallable(CallableFunctions.TRIGGER_ANALYTICS);

    try {
      await triggerAnalytics({ surveyId, deploymentId, waveId });
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        ANALYTICS_TRIGGER_PROCESSING_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Analytics started processing the responses');

    return dispatch(ANALYTICS_TRIGGER_PROCESSING_SUCCESS());
  };
};

export const analyticsClearState = () => (dispatch) =>
  dispatch(ANALYTICS_CLEAR_STATE());
