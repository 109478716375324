import React, { useCallback, useEffect, useMemo } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { shallowEqual, useSelector } from 'react-redux';

import './DatePicker.scss';

registerLocale('en', en);
registerLocale('es', es);

const dateFormat = (locale, hours = false) => {
  switch (locale) {
    case 'en':
      if (hours) {
        return 'MM-dd-yy HH:mm';
      }
      return 'MM-dd-yy';
    case 'es':
      if (hours) {
        return 'dd/MM/yy HH:mm';
      }
      return 'dd/MM/yy';
    default:
      if (hours) {
        return 'MM-dd-yy HH:mm';
      }
      return 'MM-dd-yy';
  }
};

const DatePickerStyled = ({
  name,
  date,
  onChange,
  setState,
  minDate,
  maxDate,
  className,
  disabled,
  openToDate,
  hours,
}) => {
  const initialDate = useMemo(() => {
    if (date) {
      return new Date(date);
    }
    return null;
  }, []);

  const normalizeDate = useCallback((value) => {
    if (!value) {
      return null;
    }

    const dateValue = new Date(value);

    if (hours) {
      dateValue.setMinutes(0);
      dateValue.setSeconds(0);
      dateValue.setMilliseconds(0);
    }

    return dateValue;
  }, []);

  useEffect(() => {
    if (initialDate === date) {
      setState((prevState) => ({
        ...prevState,
        [name]: normalizeDate(initialDate),
      }));
    }
  }, [initialDate, name, setState]);

  const onDateChangedHandler = (value) => {
    const dateValue = normalizeDate(value);
    const currentDate = new Date();

    if (onChange) {
      return onChange(value);
    }

    if (dateValue.getTime() < currentDate.getTime()) {
      return setState((prevState) => ({
        ...prevState,
        [name]: dateValue,
      }));
    }

    return setState((prevState) => ({
      ...prevState,
      [name]: dateValue,
    }));
  };

  const filterPassedTime = useCallback(
    (time) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);

      if (!hours) {
        return true;
      }

      return currentDate.getTime() < selectedDate.getTime();
    },
    [hours]
  );

  const { locale } = useSelector(
    (state) => ({
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

  return (
    <DatePicker
      locale={locale}
      showTimeSelect={hours}
      dateFormat={dateFormat(locale, hours)}
      filterTime={filterPassedTime}
      selected={date}
      minDate={minDate}
      maxDate={maxDate}
      timeIntervals={60}
      onChange={onDateChangedHandler}
      className={className}
      disabled={disabled}
      openToDate={openToDate}
    />
  );
};

DatePickerStyled.propTypes = {
  name: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  hours: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  openToDate: PropTypes.instanceOf(Date),
};

DatePickerStyled.defaultProps = {
  date: null,
  onChange: null,
  className: '',
  disabled: false,
  openToDate: null,
  minDate: null,
  maxDate: null,
  hours: false,
};

export default DatePickerStyled;
