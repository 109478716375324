import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InstantSearch } from 'react-instantsearch-dom';
import classNames from 'classnames';

import { REACT_APP_ALGOLIA_SECTIONS_SAVES_INDEX } from 'constants/environment';
import { initAlgoliaClient } from 'utils';
import { useAlgoliaSearch } from 'hooks';
import { SearchBox, Table } from 'components/Algolia';
import RemoveSectionSaveConfirmationModal from 'components/SavedSections/Modals/RemoveSectionSaveConfirmationModal';
import useNewModal from 'hooks/useNewModal';
import {
  clearSavedSectionsState,
  deleteSectionSave,
} from 'state/actions/SavedSections';
import { selectSectionSaveDeletion } from 'state/selectors/sectionsSaves';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import paths from 'pages/Router/paths';

const searchClient = initAlgoliaClient();
const index = REACT_APP_ALGOLIA_SECTIONS_SAVES_INDEX;

const columns = ({ onRemoveSectionHandler }) => [
  {
    Header: 'Identifier',
    accessor: 'sectionIdentifier',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: '',
    accessor: 'actions',
    Cell: ({ row }) => (
      <div className={classNames('buttons is-right')}>
        <Link
          to={`${paths.SAVED_SECTIONS}/${row.original.objectID}`}
          className="button is-small"
          data-tip="Edit latest version"
        >
          <span className="icon is-small">
            <i className="mdi mdi-pencil" />
          </span>
        </Link>
        <button
          type="button"
          className="button is-danger is-small"
          onClick={() => onRemoveSectionHandler(row.original.objectID)}
          data-tip="Delete Save"
        >
          <span className="icon is-small">
            <i className="mdi mdi-delete" />
          </span>
        </button>
      </div>
    ),
  },
];

const SavedSections = () => {
  const dispatch = useDispatch();
  const { search, refresh } = useAlgoliaSearch();
  const { isOpen, toggle } = useNewModal();

  const { error, loading, success } = useSelector(selectSectionSaveDeletion);

  const [objectIDDelete, setObjectIDToDelete] = useState('');

  const onRemoveSectionHandler = (objectID) => {
    toggle();
    setObjectIDToDelete(objectID);
  };

  const onConfirmRemoveSection = () => {
    dispatch(deleteSectionSave({ objectID: objectIDDelete }));
  };

  useEffect(() => {
    if (success || error) {
      refresh();
      toggle();
      setObjectIDToDelete('');
      dispatch(clearSavedSectionsState());
    }
  }, [success, error, refresh, toggle]);

  return (
    <>
      <RemoveSectionSaveConfirmationModal
        isOpen={isOpen}
        toggle={toggle}
        onSubmit={onConfirmRemoveSection}
        loading={loading}
      />
      <InstantSearch
        searchClient={searchClient}
        indexName={index}
        refresh={search.refresh}
      >
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">Sections Saves</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-main-section">
          <div className="card has-table has-mobile-sort-spaced">
            <header className="card-header">
              <p className="card-header-title table-header">
                <span>Search</span>
                <SearchBox />
              </p>
            </header>
            <Table
              columns={columns({ onRemoveSectionHandler })}
              defaultPerPageOption={10}
            />
          </div>
        </section>
      </InstantSearch>
    </>
  );
};

export default SavedSections;
