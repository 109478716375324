import { toastr } from 'react-redux-toastr';
import { deleteSurveyService } from 'services/surveys';
import {
  SURVEYS_DELETE_SURVEY_FAIL,
  SURVEYS_DELETE_SURVEY_INIT,
  SURVEYS_DELETE_SURVEY_SUCCESS,
} from 'state/actionCreators/surveys';
import { ERROR_RESPONSE, firebaseError } from 'utils';

export const deleteSurvey = ({ surveyId }) => {
  return async (dispatch) => {
    dispatch(SURVEYS_DELETE_SURVEY_INIT());

    try {
      await deleteSurveyService(surveyId);
    } catch (error) {
      let errorMessage;
      if (error.message === ERROR_RESPONSE.DELETE_SURVEY_HAS_RESPONSES) {
        errorMessage = firebaseError(error.message, 'en');
        toastr.error('', errorMessage);
      } else {
        errorMessage = firebaseError(error.code, 'en');
        toastr.error('', errorMessage);
      }

      dispatch(SURVEYS_DELETE_SURVEY_FAIL({ error: errorMessage }));
    }
    dispatch(SURVEYS_DELETE_SURVEY_SUCCESS());
  };
};
