/* eslint-disable no-param-reassign */
import processDeploymentQuestionGroupItem from 'utils/actions/surveys/processDeploymentQuestionGroupItem';
import processDeploymentQuestionItem from 'utils/actions/surveys/processDeploymentQuestionItem';
import { SurveyElement } from './enums';

const assignPreviewData = ({
  waveId,
  surveyId,
  organizationId,
  itemsClone,
  sectionsClone,
  newActions,
  deployment,
}) => {
  const deploymentId = 'previewId';
  const questions = [];
  const questionTag = [];
  const questionChoices = [];
  const demographics = [];
  const dataSetIds = [];
  const allQuestionsTags = [];

  Object.values(itemsClone).forEach((item) => {
    if (item.type === SurveyElement.QUESTION) {
      const { question } = item;

      question.tags.forEach((tag) => {
        const tagAlreadyIncluded = allQuestionsTags.some(
          (tagInArray) => tagInArray.id === tag.id
        );
        if (!tagAlreadyIncluded) {
          allQuestionsTags.push(tag);
        }
      });
    }

    if (item.type === SurveyElement.QUESTION_GROUP) {
      const { questionGroup } = item;

      questionGroup.questions.forEach((question) => {
        question.tags.forEach((tag) => {
          const tagAlreadyIncluded = allQuestionsTags.some(
            (tagInArray) => tagInArray.id === tag.id
          );

          if (!tagAlreadyIncluded) {
            allQuestionsTags.push(tag);
          }
        });
      });
    }
  });

  const previousDemographics = [];

  Object.values(itemsClone).forEach((item) => {
    if (item.type === SurveyElement.QUESTION) {
      const { id, question } = item;

      processDeploymentQuestionItem({
        id,
        items: itemsClone,
        waveId,
        surveyId,
        deploymentId,
        organizationId,
        sections: sectionsClone,
        question,
        questions,
        newActions,
        dataSetIds,
        questionTag,
        demographics,
        questionChoices,
        previousDemographics,
        isPreview: true,
      });
    }

    if (item.type === SurveyElement.QUESTION_GROUP) {
      const {
        id: questionGroupId,
        questionGroup: {
          dataSet,
          questions: questionsInGroup,
          choices: questionGroupChoices,
        },
      } = item;

      let dataSetId = dataSet.id;

      if (Array.isArray(dataSet)) {
        dataSetId = [];

        dataSet.forEach((dataSetItem) => {
          if (dataSetItem.id) {
            dataSetId.push(dataSetItem.id);
          }
        });
      }

      processDeploymentQuestionGroupItem({
        waveId,
        surveyId,
        dataSetId,
        deploymentId,
        organizationId,
        questionGroupId,
        questionsInGroup,
        items: itemsClone,
        questions,
        newActions,
        questionTag,
        demographics,
        questionGroupChoices,
        previousDemographics,
        isPreview: true,
      });
    }
  });

  deployment.actions = newActions;
};

export default assignPreviewData;
