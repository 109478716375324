import { useCallback, useState } from 'react';

const useNewModal = (openInitialState = false) => {
  const [isOpen, setIsOpen] = useState(openInitialState);

  const toggle = useCallback(() => setIsOpen((prevState) => !prevState), []);

  const openModal = useCallback(() => setIsOpen(true), []);

  const closeModal = useCallback(() => setIsOpen(false), []);

  return { isOpen, toggle, openModal, closeModal };
};

export default useNewModal;
