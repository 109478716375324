import { toastr } from 'react-redux-toastr';
import {
  createSurveyDeploymentService,
  updateSurveyService,
} from 'services/surveys';

import {
  SURVEYS_CREATE_DEPLOYMENT_FAIL,
  SURVEYS_CREATE_DEPLOYMENT_INIT,
  SURVEYS_CREATE_DEPLOYMENT_SUCCESS,
} from 'state/actionCreators/surveys';

export const createDeployment = (
  surveyVersionData,
  surveyDeploymentData,
  { override = false } = {}
) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_CREATE_DEPLOYMENT_INIT());

    try {
      const result = await updateSurveyService(
        { surveyData: surveyVersionData, override, isCreatingDeployment: true },
        { getState }
      );

      if (result?.error) {
        return dispatch(
          SURVEYS_CREATE_DEPLOYMENT_FAIL({
            error: result.error,
            data: result.data,
          })
        );
      }

      await createSurveyDeploymentService(
        { surveyData: surveyDeploymentData },
        { getState }
      );
    } catch (error) {
      console.error('Error Deploying Survey', error);
      
      toastr.error('Error Deploying Survey', error);
      return dispatch(SURVEYS_CREATE_DEPLOYMENT_FAIL({ error }));
    }

    toastr.success('', 'Survey successfully deployed.');

    return dispatch(SURVEYS_CREATE_DEPLOYMENT_SUCCESS());
  };
};
