/* eslint-disable prefer-destructuring */
import firebase from 'firebase';
import { createIntl, createIntlCache } from 'react-intl';
import algoliasearch from 'algoliasearch/lite';
import { LanguageIsoCode } from 'utils/enums';
import african from 'languages/af.json';
import arabic from 'languages/ar.json';
import bengali from 'languages/bn.json';
import traditionalChinese from 'languages/zh_cht.json';
import simplifiedChinese from 'languages/zh_chs.json';
import czech from 'languages/cs.json';
import danish from 'languages/da.json';
import dutch from 'languages/nl.json';
import english from 'languages/en.json';
import finnish from 'languages/fi.json';
import french from 'languages/fr.json';
import german from 'languages/de.json';
import greek from 'languages/el.json';
import spanish from 'languages/es.json';
import hindi from 'languages/hi.json';
import hungarian from 'languages/hu.json';
import italian from 'languages/it.json';
import japanese from 'languages/ja.json';
import korean from 'languages/ko.json';
import norwegian from 'languages/no.json';
import persian from 'languages/fa.json';
import punjabi from 'languages/pa.json';
import polish from 'languages/pl.json';
import portuguese from 'languages/pt.json';
import romanian from 'languages/ro.json';
import russian from 'languages/ru.json';
import serbian from 'languages/sr.json';
import slovak from 'languages/sk.json';
import swedish from 'languages/sv.json';
import tagalog from 'languages/tl.json';
import turkish from 'languages/tr.json';
import ukrainian from 'languages/uk.json';
import urdu from 'languages/ur.json';
import vietnamese from 'languages/vi.json';
import en from 'assets/en.png';
import es from 'assets/es.png';

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: 'auth/email-already-exists',
  EMAIL_INVALID: 'auth/invalid-email',
  EMAIL_NOT_FOUND: 'auth/user-not-found',
  PASSWORD_INVALID: 'auth/wrong-password',
  USER_DISABLED: 'auth/user-disabled',
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
  EXPIRED_ACTION_CODE: 'auth/expired-action-code',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  QUOTA_EXCEEDED_STORAGE: 'storage/quota-exceeded',
  UNAUTHENTICATED_STORAGE: 'storage/unauthenticated',
  UNAUTHORIZED_STORAGE: 'storage/unauthorized',
};

export const ERROR_RESPONSE = {
  USER_IS_REPORT_VIEWER: 'auth/user-is-report-viewer',
  DELETE_SURVEY_HAS_RESPONSES: 'surveys/delete-survey-has-responses',
};

export const messages = {
  af: african,
  ar: arabic,
  bn: bengali,
  zhcht: traditionalChinese,
  zhchs: simplifiedChinese,
  cs: czech,
  da: danish,
  nl: dutch,
  en: english,
  fi: finnish,
  fr: french,
  de: german,
  el: greek,
  es: spanish,
  hi: hindi,
  hu: hungarian,
  it: italian,
  ja: japanese,
  ko: korean,
  no: norwegian,
  fa: persian,
  pa: punjabi,
  pl: polish,
  pt: portuguese,
  ro: romanian,
  ru: russian,
  sr: serbian,
  sk: slovak,
  sv: swedish,
  tl: tagalog,
  tr: turkish,
  uk: ukrainian,
  ur: urdu,
  vi: vietnamese,
};

const getIntlContext = (locale) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache
  );
};

export const firebaseError = (error, locale) => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: error,
    defaultMessage: messages[locale]['utils.default'],
  });
};

export const validateEmail = (email) => {
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
  );
};

export const inputValidations = (email, password, locale) => {
  let inputs = {
    email: {
      modifier: null,
      message: null,
    },
    password: {
      modifier: null,
      message: null,
    },
    canSubmit: null,
  };
  const intl = getIntlContext(locale);

  const setInputs = (key, value) => {
    inputs = { ...inputs, [`${key}`]: value };
  };

  const isValidEmail = email && validateEmail(email);

  if (email && !isValidEmail) {
    setInputs('email', {
      modifier: 'is-danger',
      message: intl.formatMessage({ id: 'utils.invalidEmail' }),
    });
  }

  const isValidPassword = password && password.length >= 6;

  if (isValidPassword) {
    setInputs('password', {
      modifier: 'is-success',
      message: intl.formatMessage({ id: 'utils.safePassword' }),
    });
  } else if (password) {
    setInputs('password', {
      modifier: 'is-danger',
      message: intl.formatMessage({ id: 'utils.unsafePassword' }),
    });
  }

  if (isValidEmail && isValidPassword) {
    setInputs('canSubmit', true);
  }

  return inputs;
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const flags = {
  en,
  es,
};

export const tagPathToType = {
  question: 'Question',
  'data-set': 'Data Set',
  construct: 'Construct',
  demographic: 'Demographic',
};

export const tagTypeOptions = [
  { value: tagPathToType.question, label: tagPathToType.question },
  { value: tagPathToType['data-set'], label: tagPathToType['data-set'] },
  { value: tagPathToType.construct, label: tagPathToType.construct },
];

export const validTagPath = (type) => !!tagPathToType[type];

export const getTagTypeOption = (type) => {
  if (validTagPath(type)) {
    return { value: tagPathToType[type], label: tagPathToType[type] };
  }
  return null;
};

export const getTypeFilter = (type) => {
  if (validTagPath(type)) {
    return `type:'${tagPathToType[type]}'`;
  }
  return null;
};

export const getCreateTagPath = (type) => {
  if (validTagPath(type)) {
    return `/tags/${type}/new`;
  }
  return '';
};

export const getListTagPath = (type) => {
  if (validTagPath(type)) {
    return `/tags/${type}`;
  }
  return '';
};

export const getModifyTagPath = (type, id) => {
  if (validTagPath(type) && id?.trim() !== '') {
    return `/tags/${type}/${id}`;
  }
  return '';
};

export const tagTypeToUrl = {
  Question: 'question',
  'Data Set': 'data-set',
  Construct: 'construct',
  Demographic: 'demographic',
};

const validTagType = (type) => !!tagTypeToUrl[type];

export const getModifyTagPathFromType = (type, id) => {
  if (validTagType(type) && id?.trim() !== '') {
    return `/tags/${tagTypeToUrl[type]}/${id}`;
  }
  return '';
};

export const validSiteNameCharacters = (siteName) => {
  const regex = /^[A-Za-z0-9-_]*$/g;
  return siteName ? siteName.trim() && regex.test(siteName) : true;
};

export const siteNameIsNew = (siteName) =>
  siteName ? siteName.trim().toLowerCase() === 'new' : false;

export const validateName = (name) =>
  name ? name.trim().toLowerCase() !== 'global' : true;

export const numberHandler = (e, onChangeHandler) => {
  const val = e.target.value;
  if (e.target.validity.valid) onChangeHandler(e);
  else if (val === '') onChangeHandler(e);
};

export const isUntilDateValid = (startingOn, until) =>
  until ? until > startingOn : true;

export const roles = {
  ADMIN: 'Admin',
  PORTAL_ADMIN: 'Portal Admin',
  REPORT_VIEWER: 'Report Viewer',
  RESPONDENT: 'Respondent',
};

export const rolesList = [
  { value: roles.ADMIN, label: roles.ADMIN },
  { value: roles.PORTAL_ADMIN, label: roles.PORTAL_ADMIN },
  { value: roles.REPORT_VIEWER, label: roles.REPORT_VIEWER },
  { value: roles.RESPONDENT, label: roles.RESPONDENT },
];

export const validateDataItems = (dataItems) =>
  dataItems.every(({ option }) => option.trim());

export const emptyDataItem = {
  option: '',
  duplicated: false,
  paramOne: '',
  paramTwo: '',
};

export const defaultDataItems = {
  en: [emptyDataItem],
};

export const initAlgoliaClient = () =>
  algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_ADMIN_KEY
  );

export const availableLanguages = (usedLanguages, defaultLangue) =>
  Object.values(LanguageIsoCode).filter(
    (language) =>
      !usedLanguages.includes(language) && language !== defaultLangue
  );

export const calculateTranslationCompletion = (
  translatingFrom,
  translation
) => {
  const totalFields = translatingFrom.filter((t) => (t || '').trim()).length;
  const totalTranslatedFields = translation.filter((t) =>
    (t || '').trim()
  ).length;
  const completionPercentage = Math.floor(
    (totalTranslatedFields * 100) / totalFields
  );

  return totalFields === 0 || totalTranslatedFields > totalFields
    ? 100
    : completionPercentage;
};

export const selectErrorStyle = (hasError) => ({
  control: (base) => ({
    ...base,
    borderColor: hasError ? 'red' : '#ddd',
    '&:hover': {
      borderColor: hasError ? 'red' : '#ddd',
    },
  }),
});

export const shuffleArray = (array) => {
  const shuffledArray = array;

  for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};

export const alphabeticalOrderAsc = (choices, surveyLanguage) => {
  return choices.sort((a, b) =>
    a.option[surveyLanguage].localeCompare(b.option[surveyLanguage])
  );
};

export const alphabeticalOrderDesc = (choices, surveyLanguage) => {
  choices.sort((a, b) =>
    a.option[surveyLanguage].localeCompare(b.option[surveyLanguage])
  );
  return choices.reverse();
};

export const transformOrganizationSelectOptions = (options, setState) => {
  const data = options.map(
    ({ displayName, id, currentWave, waves, name, displaySiteName }) => {
      const wavesOpt = waves.map((wave) => ({ label: wave, value: wave }));
      return {
        label: displayName,
        value: {
          displayName,
          waves: wavesOpt,
          id,
          currentWave,
          name,
          displaySiteName,
        },
      };
    }
  );
  return setState(data);
};

export const mergeDemographics = (firstDemographics, secondDemographics) => {
  const allDemographics = [...firstDemographics, ...secondDemographics];
  const mergedDemographics = [];

  allDemographics.forEach((demographic) => {
    const demographicAlreadyAdded = mergedDemographics.find(
      (demo) => demo.demographicId === demographic.demographicId
    );

    if (demographicAlreadyAdded) {
      const mergedChoices = [...demographic.name];

      demographicAlreadyAdded.name.forEach((option) => {
        if (!mergedChoices.includes(option)) {
          mergedChoices.push(option);
        }
      });

      // eslint-disable-next-line no-param-reassign
      demographicAlreadyAdded.name = mergedChoices;
    } else {
      mergedDemographics.push(demographic);
    }
  });

  return mergedDemographics;
};

export const getUniqueValues = (array = []) =>
  array.filter((value, index, self) => self.indexOf(value) === index);

export const createError = (message, data = {}) => ({
  error: { message, data },
});

export const onlyLettersAndNumbers = (val) =>
  val.trim().replace(/[^a-zA-Z0-9]/g, '');

export const textFilter = (value, filter, setError, errorMessage) => {
  const newValue = filter(value);

  if (newValue !== value) {
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setError(true);
    }
  } else {
    setError(false);
  }

  return newValue;
};

export const getTranslation = (
  translations,
  languageCode,
  fallback = LanguageIsoCode.EN
) => translations?.[languageCode] || translations?.[fallback] || '';

export const increment = (value = 1) =>
  firebase.firestore.FieldValue.increment(value);

export const paginateArray = (array, offset) => {
  const slices = [];

  for (let index = 0; index <= array.length; index += offset) {
    slices.push(array.slice(index, index + offset));
  }

  return slices;
};

export const parseSearchParams = (searchParam) => {
  const parsedParams = {};
  const separatedParams = searchParam?.replace('?', '')?.split('&');

  if (separatedParams?.length) {
    separatedParams.forEach((param) => {
      const [name, value] = param.split('=');

      parsedParams[name] = value;
    });
  }

  return parsedParams;
};

export const wait = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const getLocalStorageValue = (key) => {
  const item = localStorage.getItem(key);

  return item ? JSON.parse(item) : null;
};

export const setLocalStorageValue = (key, valueToStore) =>
  localStorage.setItem(key, JSON.stringify(valueToStore));

export const rollbackOperations = async (operations) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const operation of operations) {
    if (operation) {
      // eslint-disable-next-line no-await-in-loop
      await operation();
    }
  }
};

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndexFromArray(array, predicate) {
  let arrayLength = array.length;

  while (arrayLength >= 0) {
    if (predicate(array[arrayLength], arrayLength, array)) return arrayLength;
    arrayLength -= 1;
  }

  return -1;
}

export const isString = (value) =>
  typeof value === 'string' || value instanceof String;
