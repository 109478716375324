import React from 'react';
import parse from 'html-react-parser';

import { getTranslation } from 'utils';
import { SurveyElement } from 'utils/surveyBuilder/enums';

import QuestionGroup from '../QuestionGroup';
import Question from '../Question';
import TextBox from '../TextBox';
import Item from '../Item';

const Items = ({
  items,
  surveyLanguage,
  disableDeselect,
  questionResponses,
  onClearAnswerHandler,
  onChangeInputHandler,
  isQuestionNotAnswered,
  isQuestionGroupNotAnswered,
  isChoiceInRequiredQuestion,
}) => {
  return (
    <>
      {items?.length &&
        items.map(
          (item) =>
            item.visible && (
              <Item key={item.id}>
                {item.type === SurveyElement.TEXT_BOX && (
                  <TextBox
                    content={parse(
                      item.richText?.[surveyLanguage.code] ||
                        item.content[surveyLanguage.code]
                    )}
                  />
                )}
                {item.type === SurveyElement.QUESTION && (
                  <Question
                    id={item.id}
                    type={item.question.type}
                    choices={item.question.choices.map((choice) => ({
                      ...choice,
                      option: choice.option[surveyLanguage.code],
                    }))}
                    content={parse(
                      item.question.richText?.[surveyLanguage.code] ||
                        item.question.content[surveyLanguage.code]
                    )}
                    rawContent={
                      item.question.content.en ||
                      item.question.content[surveyLanguage.code]
                    }
                    onChangeInput={onChangeInputHandler}
                    currentResponse={questionResponses[item.id]}
                    singleLine={item.question.singleLine}
                    required={item.question.required}
                    error={isQuestionNotAnswered(item.id)}
                    onClearAnswer={onClearAnswerHandler}
                    disableDeselect={disableDeselect}
                    sliderProps={item.question.sliderProps}
                  />
                )}
                {item.type === SurveyElement.QUESTION_GROUP && (
                  <QuestionGroup
                    onChangeInput={onChangeInputHandler}
                    currentResponse={questionResponses}
                    label={getTranslation(item.questionGroup.label)}
                    questions={item.questionGroup.questions
                      .filter((question) => question.visible)
                      .map((question) => ({
                        ...question,
                        rawContent:
                          question.content.en ||
                          question.content[surveyLanguage.code],
                        content: parse(
                          question?.richText?.[surveyLanguage.code] ||
                            question.content[surveyLanguage.code]
                        ),
                      }))}
                    surveyLanguage={surveyLanguage}
                    choices={item.questionGroup.choices}
                    columnWidths={item.questionGroup.columnWidths}
                    error={isQuestionGroupNotAnswered(item.questionGroup)}
                    isChoiceInRequiredQuestion={isChoiceInRequiredQuestion}
                  />
                )}
              </Item>
            )
        )}
    </>
  );
};

export default Items;
