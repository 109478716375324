/* eslint-disable no-param-reassign */
import { v4 as uuid } from 'uuid';
import { getQuestionDemographics, newActionQuestionGroupAssign } from './surveys';

const processDeploymentQuestionGroupItem = ({
  // Identifiers
  waveId,
  surveyId,
  dataSetId,
  deploymentId,
  organizationId,
  questionGroupId,

  // Static data
  questionsInGroup,

  // Dynamic data
  items,
  questions,
  newActions,
  questionTag,
  demographics,
  questionGroupChoices,
  previousDemographics,
  isPreview = false,
}) => {
  questionsInGroup.forEach(
    (
      {
        id: oldQuestionId,
        content,
        tags,
        type: questionInGroupType,
        required,
        choicesOrder,
        template = null,
      },
      questionIndex
    ) => {
      const questionInGroupId = isPreview ? oldQuestionId : uuid();

      const questionDemographics = getQuestionDemographics(
        waveId,
        tags,
        questionGroupChoices,
        previousDemographics
      );

      // Iteration on the current demographics tags.
      // If the tagId and waveId are the same, merge the choices of the tag in the one that's already created.
      // If the tag is different, then add the demographic tag to the demographics array to be created.
      if (demographics.length === 0) {
        demographics.push(...questionDemographics);
      } else {
        demographics.forEach(({ demographic }) => {
          const sameQuestionDemographic = questionDemographics.find(
            (questionDemographic) =>
              demographic.tagId === questionDemographic.demographic.tagId &&
              demographic.waveId === questionDemographic.demographic.waveId
          );

          const differentQuestionDemographic = questionDemographics.find(
            (questionDemographic) =>
              demographic.tagId !== questionDemographic.demographic.tagId
          );

          if (sameQuestionDemographic) {
            const newContent = [...demographic.content];

            sameQuestionDemographic.demographic.content.forEach((option) => {
              if (!newContent.includes(option)) {
                newContent.push(option);
              }
            });

            demographic.content = newContent;
          }

          if (differentQuestionDemographic) {
            demographics.push(differentQuestionDemographic);
          }
        });
      }

      const demographicsId =
        questionDemographics.length !== 0
          ? questionDemographics.map((demographic) => demographic.id)
          : null;

      items[questionGroupId].questionGroup.questions[questionIndex].id =
        questionInGroupId;
      items[questionGroupId].questionGroup.questions[
        questionIndex
      ].demographicsId = demographicsId;

      const questionContentParsed = {};

      Object.entries(content).forEach(([language, questionContent]) => {
        questionContentParsed[language] = questionContent.trim();
      });

      questions.push({
        id: questionInGroupId,
        question: {
          content: questionContentParsed,
          type: questionInGroupType,
          dataSetId: dataSetId ?? null,
          surveyId,
          surveyVersionId: null,
          surveyDeploymentId: deploymentId,
          template,
          required,
          choicesOrder,
          regionalBreakdown: false,
          organizationId,
          demographicsId,
        },
      });

      tags.forEach(({ id: tagId, weight }) => {
        questionTag.push({
          tagId,
          questionId: questionInGroupId,
          weight,
        });
      });

      newActionQuestionGroupAssign(
        newActions,
        oldQuestionId,
        questionInGroupId
      );
    }
  );
};

export default processDeploymentQuestionGroupItem;
