import { toastr } from 'react-redux-toastr';

import { updateSurveyService } from 'services/surveys';
import {
  SURVEYS_UPDATE_SURVEY_FAIL,
  SURVEYS_UPDATE_SURVEY_INIT,
  SURVEYS_UPDATE_SURVEY_SUCCESS,
} from 'state/actionCreators/surveys';
import { SurveyBuilderErrors } from 'utils/enums';

export const updateSurvey = (
  surveyData,
  { override } = { override: false }
) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_UPDATE_SURVEY_INIT());

    try {
      const result = await updateSurveyService(
        { surveyData, override },
        { getState }
      );

      if (result) {
        const { error, data } = result;

        if (error === SurveyBuilderErrors.OVERRIDING_OTHER_USER_UPDATES) {
          return dispatch(SURVEYS_UPDATE_SURVEY_FAIL({ error, data }));
        }
      }
    } catch (error) {
      console.log(error);

      toastr.error('Error', error);

      return dispatch(SURVEYS_UPDATE_SURVEY_FAIL({ error }));
    }

    toastr.success('', 'Survey updated successfully');
    return dispatch(SURVEYS_UPDATE_SURVEY_SUCCESS());
  };
};
