import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import { firebaseError } from 'utils';
import { CallableFunctions } from 'utils/enums';

export const REPROCESS_RESPONSES_INIT = createAction(
  'REPROCESS_RESPONSES_INIT'
);
export const REPROCESS_RESPONSES_SUCCESS = createAction(
  'REPROCESS_RESPONSES_SUCCESS'
);
export const REPROCESS_RESPONSES_FAIL = createAction(
  'REPROCESS_RESPONSES_FAIL'
);

export const REPROCESS_RESPONSES_CLEAR_STATE = createAction(
  'REPROCESS_RESPONSES_CLEAR_STATE'
);

export const reprocessResponses = (waveId) => {
  return async (dispatch, getState) => {
    dispatch(REPROCESS_RESPONSES_INIT());

    toastr.info('', 'Reprocessing responses');

    const { locale } = getState().preferences;

    const triggerReprocessResponses = firebase
      .functions()
      .httpsCallable(CallableFunctions.REPROCESS_RESPONSES);

    try {
      await triggerReprocessResponses(waveId);
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        REPROCESS_RESPONSES_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Responses were successfully reprocessed');

    return dispatch(REPROCESS_RESPONSES_SUCCESS());
  };
};

export const reprocessResponsesClearState = () => (dispatch) =>
  dispatch(REPROCESS_RESPONSES_CLEAR_STATE());
