import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ModalType } from 'utils/enums';
import { parseSearchParams } from 'utils';
import useNewModal from 'hooks/useNewModal';
import Card from 'components/Card';
import Input, { Type as InputType } from 'components/Input';
import Button from 'components/Button';
import Collapse from 'components/Collapse';
import CardBody from 'components/Card/CardBody';
import Items from 'components/SurveyBuilder/Items';
import CardHeader from 'components/Card/CardHeader';
import Dropdown from 'components/Navigation/Dropdown';
import SaveSectionModal from 'components/SurveyBuilder/Modals/SaveSectionModal';
import LoadSectionModal from 'components/SurveyBuilder/Modals/LoadSectionModal';

import classes from './Section.module.scss';

const Section = ({
  survey,
  setSurvey,
  sectionId,
  sectionIndex,
  pageId,
  pageIndex,
  title,
  visible,
  collapsed,
  randomize,
  onAddSection,
  items,
  onOpenModal,
  deploymentId,
  canDeleteSection,
  isEditingSavedSection = false,
}) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const {
    toggle: toggleSaveSectionModal,
    isOpen: isOpenSaveSectionModal,
    closeModal: closeSaveSectionModal,
  } = useNewModal();
  const {
    toggle: toggleLoadSectionModal,
    isOpen: isOpenLoadSectionModal,
    closeModal: closeLoadSectionModal,
  } = useNewModal();

  const { section, page } = parseSearchParams(search);

  const onSectionPropertyChangeHandler = useCallback(
    (prop) =>
      setSurvey((prevState) => {
        const { sections } = prevState;
        const surveySection = sections[sectionId];

        return {
          ...prevState,
          sections: {
            ...sections,
            [sectionId]: {
              ...surveySection,
              ...prop,
            },
          },
        };
      }),
    [setSurvey, sectionId]
  );

  const onSectionVisibilityChangeHandler = useCallback(
    (setVisible) =>
      setSurvey((prevState) => {
        const { sections } = prevState;

        const surveySection = sections[sectionId];

        surveySection.visible = setVisible;

        return { ...prevState, sections };
      }),
    [setSurvey, sectionId]
  );

  const changeSectionHandler = useCallback(
    (sectionToGo) => {
      history.replace(`${pathname}?page=${page}&section=${sectionToGo}`);
    },
    [history, pathname, page]
  );

  const onRandomizeHandler = useCallback(
    () => onSectionPropertyChangeHandler({ randomize: !randomize }),
    [onSectionPropertyChangeHandler, randomize]
  );

  const onCollapseHandler = useCallback(() => {
    changeSectionHandler(sectionIndex + 1);
    onSectionPropertyChangeHandler({ collapsed: !collapsed });
  }, [
    onSectionPropertyChangeHandler,
    changeSectionHandler,
    sectionIndex,
    collapsed,
  ]);

  const onAddSectionHandler = useCallback(() => {
    onAddSection(sectionIndex + 1);
    changeSectionHandler(sectionIndex + 2);
  }, [changeSectionHandler, onAddSection, sectionIndex]);

  const onVisibilityChangeHandler = useCallback(
    () => onSectionVisibilityChangeHandler(!visible, sectionId),
    [onSectionVisibilityChangeHandler, sectionId, visible]
  );

  const onDeleteSectionHandler = useCallback(() => {
    if (canDeleteSection(sectionId)) {
      onOpenModal(ModalType.DELETE_SURVEY_SECTION, { sectionIndex, pageId });
    } else {
      onOpenModal(ModalType.CANNOT_DELETE_SURVEY_SECTION);
    }
  }, [canDeleteSection, sectionId, onOpenModal, sectionIndex, pageId]);

  useEffect(() => {
    if (Number(section) === sectionIndex + 1) {
      onSectionPropertyChangeHandler({ collapsed: false });
    }
  }, [onSectionPropertyChangeHandler, section, sectionIndex]);

  useEffect(() => {
    onSectionPropertyChangeHandler({ collapsed: true });
  }, [onSectionPropertyChangeHandler]);

  const onSectionTitleChangeHandler = useCallback(
    (e) => {
      const { value } = e.target;

      setSurvey((prevState) => {
        const { sections, selectedLanguage } = prevState;
        const surveySection = sections[sectionId];

        return {
          ...prevState,
          sections: {
            ...sections,
            [sectionId]: {
              ...surveySection,
              title: {
                ...surveySection.title,
                [selectedLanguage]: value,
              },
            },
          },
        };
      });
    },
    [setSurvey, sectionId]
  );

  const pageIndexToRender = Number.isInteger(pageIndex)
    ? `${pageIndex + 1}.`
    : '';

  return (
    <>
      {!isEditingSavedSection && (
        <SaveSectionModal
          isOpen={isOpenSaveSectionModal}
          survey={survey}
          sectionId={sectionId}
          pageId={pageId}
          toggle={toggleSaveSectionModal}
          closeModal={closeSaveSectionModal}
        />
      )}
      {!isEditingSavedSection && (
        <LoadSectionModal
          isOpen={isOpenLoadSectionModal}
          toggle={toggleLoadSectionModal}
          closeModal={closeLoadSectionModal}
        />
      )}
      <Card visible={visible}>
        <CardHeader>
          <div className="level" data-section-id={sectionId}>
            <div className="level-left">
              <div className="level-item">
                <div className={classes['page-count']}>{`${pageIndexToRender}${
                  sectionIndex + 1
                }`}</div>
                <Input
                  id="title"
                  type={InputType.Text}
                  placeHolder="Section title"
                  value={title}
                  onChange={onSectionTitleChangeHandler}
                />
              </div>
              {randomize && (
                <div className="level-item">
                  <span className={classNames('tag', classes.randomized)}>
                    {' '}
                    Randomized
                  </span>
                </div>
              )}
            </div>
            <div className="level-right">
              <div className="level-item">
                <div className="field is-grouped is-grouped-multiline">
                  <div className="control">
                    <Button
                      id="change-visibility"
                      icon={classNames('mdi', {
                        'mdi-eye': visible,
                        'mdi-eye-off': !visible,
                      })}
                      onClick={onVisibilityChangeHandler}
                    />
                  </div>
                  <div className="control">
                    <Dropdown
                      id="section-actions"
                      placeHolder="Section Actions"
                    >
                      {!isEditingSavedSection && (
                        <>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={onAddSectionHandler}
                          >
                            <span className="icon">
                              <i className="mdi mdi-plus-circle-outline" />
                            </span>
                            Add Section
                          </button>
                          <button
                            type="button"
                            className={classNames('dropdown-item', {
                              [classes.hide]: deploymentId,
                            })}
                            onClick={onDeleteSectionHandler}
                          >
                            <span className="icon">
                              <i className="mdi mdi-close-circle-outline" />
                            </span>
                            Remove Section
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        className={classNames('dropdown-item', {
                          [classes['is-randomized']]: randomize,
                        })}
                        onClick={onRandomizeHandler}
                      >
                        <span className="icon">
                          <i className="mdi mdi-shuffle-variant" />
                        </span>
                        <span>Randomize</span>
                      </button>
                      {!isEditingSavedSection && (
                        <>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={toggleSaveSectionModal}
                          >
                            <span className="icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span>Save Section</span>
                          </button>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={toggleLoadSectionModal}
                          >
                            <span className="icon">
                              <i className="mdi mdi-download" />
                            </span>
                            <span>Load Section</span>
                          </button>
                        </>
                      )}
                    </Dropdown>
                  </div>
                  <div className="control">
                    <Collapse
                      id="collapse-section"
                      collapsed={collapsed}
                      onCollapse={onCollapseHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody collapsed={collapsed}>
          <Items
            survey={survey}
            setSurvey={setSurvey}
            sectionId={sectionId}
            sectionIndex={sectionIndex}
            pageIndex={pageIndex}
            items={items}
            sectionIsVisible={visible}
            onOpenModal={onOpenModal}
            deploymentId={deploymentId}
          />
        </CardBody>
      </Card>
    </>
  );
};

Section.propTypes = {
  sectionId: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  pageIndex: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  onAddSection: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  randomize: PropTypes.bool.isRequired,
  isEditingSavedSection: PropTypes.bool,
  survey: PropTypes.shape({}).isRequired,
  setSurvey: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  canDeleteSection: PropTypes.func.isRequired,
  deploymentId: PropTypes.string,
};

export default Section;
