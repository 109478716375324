import {
  SURVEYS_CLEAR_SELECTED_VERSION,
  SURVEYS_CLEAR_STATE,
  SURVEYS_COPY_SURVEY_CLEAN_UP,
  SURVEYS_CREATE_DEPLOYMENT_CLEAR_STATE,
  SURVEYS_LOAD_RESPONSE_SAVE_STATE_CLEAN_UP,
  SURVEYS_PREVIEW_CLEANUP,
  SURVEYS_RESPONSE_SAVE_STATE_CLEAN_UP,
  SURVEYS_UPDATE_CLEAR_STATE,
  SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_CLEAR_STATE,
} from 'state/actionCreators/surveys';
import { setLocalStorageValue } from 'utils';

export const clearSelectedVersion = () => (dispatch) =>
  dispatch(SURVEYS_CLEAR_SELECTED_VERSION());

export const clearSurveyUpdatedState = () => (dispatch) =>
  dispatch(SURVEYS_UPDATE_CLEAR_STATE());

export const clearSurveyPreviewStorage = () => (dispatch) => {
  setLocalStorageValue('preview', null);

  dispatch(SURVEYS_PREVIEW_CLEANUP());
};

export const clearSurveyCreateDeploymentState = () => (dispatch) =>
  dispatch(SURVEYS_CREATE_DEPLOYMENT_CLEAR_STATE());

export const surveysClearState = () => (dispatch) =>
  dispatch(SURVEYS_CLEAR_STATE());

export const clearSurveyCopyState = () => (dispatch) =>
  dispatch(SURVEYS_COPY_SURVEY_CLEAN_UP());

export const clearSurveyUpdateStartAndEndDatesState = () => (dispatch) =>
  dispatch(SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_CLEAR_STATE());

export const clearSurveysResponseSaveState = () => (dispatch) =>
  dispatch(SURVEYS_RESPONSE_SAVE_STATE_CLEAN_UP());

export const clearSurveysResponseLoadState = () => (dispatch) =>
  dispatch(SURVEYS_LOAD_RESPONSE_SAVE_STATE_CLEAN_UP());
