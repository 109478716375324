/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useRef, useMemo, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

import paths from 'pages/Router/paths';
import { useFormatDate } from 'hooks';
import {
  SurveyTableClickableColumn,
  SurveyType,
} from 'utils/surveyBuilder/enums';

const onClickSurveyTableRowHandler = (row, rowType, column, setColumn) => {
  if (row.isExpanded) {
    if (column[row.index] === rowType) {
      setColumn((prevState) => ({
        ...prevState,
        [row.index]: null,
      }));
      row.toggleRowExpanded();
    } else {
      setColumn((prevState) => ({
        ...prevState,
        [row.index]: rowType,
      }));
    }
  } else {
    setColumn((prevState) => ({
      ...prevState,
      [row.index]: rowType,
    }));
    row.toggleRowExpanded();
  }
};

const templateSurveyColumns = ({
  skipPageResetRef,
  columnClicked,
  setColumnClicked,
  onUseLatestVersionHandler = null,
  onDeleteSurveyHandler = null,
}) => [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Versions',
    accessor: 'versionCount',
    Cell: ({ row }) => (
      <a
        {...row.getToggleRowExpandedProps({
          onClick: () => {
            skipPageResetRef.current = true;
            onClickSurveyTableRowHandler(
              row,
              SurveyTableClickableColumn.VERSIONS,
              columnClicked,
              setColumnClicked
            );
          },
        })}
      >
        {row.original.versionCount}
      </a>
    ),
  },
  {
    Header: 'Created at',
    accessor: 'createdAt',
    Cell: ({ row }) => (
      <small className="has-text-grey is-abbr-like">
        {useFormatDate(row.original.createdAt, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </small>
    ),
  },
  {
    Header: 'Created by',
    accessor: 'createdBy',
    Cell: ({ row }) => (
      <small className="has-text-grey is-abbr-like">
        {row.original.createdBy}
      </small>
    ),
  },
  {
    Header: '',
    id: 'actions',
    accessor: 'actions',
    Cell: ({ row }) => {
      const surveyId = row.original.objectID;
      const versionId =
        row.original.versions[row.original.versions.length - 1].id;

      return (
        <div className="buttons">
          <button
            type="button"
            className="button is-info is-small"
            data-tip="Use latest version"
            onClick={() => {
              const { versions, objectID, name, languageCompletion } =
                row.original;

              const latestVersion = versions[versions.length - 1];

              if (onUseLatestVersionHandler) {
                onUseLatestVersionHandler({
                  surveyId: objectID,
                  surveyName: name,
                  versionName: latestVersion.name,
                  versionId: latestVersion.id,
                  languageCompletion,
                });
              }
            }}
          >
            <span className="icon is-small">
              <i className="mdi mdi-cursor-default-click" />
            </span>
          </button>
          <Link
            to={`${paths.SURVEYS}/${surveyId}/versions/${versionId}`}
            className="button is-small"
            data-tip="Edit latest version"
          >
            <span className="icon is-small">
              <i className="mdi mdi-pencil" />
            </span>
          </Link>
          <button
            type="button"
            className="button is-danger is-small"
            onClick={() =>
              onDeleteSurveyHandler({
                surveyId,
                versionId,
              })
            }
            data-tip="Delete survey"
          >
            <span className="icon is-small">
              <i className="mdi mdi-delete" />
            </span>
          </button>
          <ReactTooltip />
        </div>
      );
    },
    disableSortBy: true,
  },
];

const organizationSurveyColumns = ({
  skipPageResetRef,
  columnClicked,
  setColumnClicked,
  onDeleteSurveyHandler = null,
}) => [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Organization',
    accessor: 'organizationName',
  },
  {
    Header: 'Versions',
    accessor: 'versionCount',
    Cell: ({ row }) => (
      <a
        {...row.getToggleRowExpandedProps({
          onClick: () => {
            skipPageResetRef.current = true;
            onClickSurveyTableRowHandler(
              row,
              SurveyTableClickableColumn.VERSIONS,
              columnClicked,
              setColumnClicked
            );
          },
        })}
      >
        {row.original.versionCount}
      </a>
    ),
  },
  {
    Header: 'Deployments',
    accessor: 'deploymentCount',
    Cell: ({ row }) => (
      <a
        {...row.getToggleRowExpandedProps({
          onClick: () => {
            skipPageResetRef.current = true;
            onClickSurveyTableRowHandler(
              row,
              SurveyTableClickableColumn.DEPLOYMENTS,
              columnClicked,
              setColumnClicked
            );
          },
        })}
      >
        {row.original.deploymentCount}
      </a>
    ),
  },
  {
    Header: 'Created at',
    accessor: 'createdAt',
    Cell: ({ row }) => (
      <small className="has-text-grey is-abbr-like">
        {useFormatDate(row.original.createdAt, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </small>
    ),
  },
  {
    Header: 'Created by',
    accessor: 'createdBy',
    Cell: ({ row }) => (
      <small className="has-text-grey is-abbr-like">
        {row.original.createdBy}
      </small>
    ),
  },
  {
    Header: '',
    id: 'actions',
    accessor: 'actions',
    Cell: ({ row }) => {
      const surveyId = row.original.objectID;
      const versionId =
        row.original.versions[row.original.versions.length - 1].id;

      return (
        <div className="buttons">
          <Link
            to={`${paths.SURVEYS}/${surveyId}/versions/${versionId}`}
            className="button is-small"
            data-tip="Edit latest version"
          >
            <span className="icon is-small">
              <i className="mdi mdi-pencil" />
            </span>
          </Link>
          <button
            type="button"
            className="button is-danger is-small"
            onClick={() => onDeleteSurveyHandler({ surveyId, versionId })}
            data-tip="Delete survey"
          >
            <span className="icon is-small">
              <i className="mdi mdi-delete" />
            </span>
          </button>
          <ReactTooltip />
        </div>
      );
    },
    disableSortBy: true,
  },
];

const surveyTableColumns = (surveyType, surveyData) => {
  switch (surveyType) {
    case SurveyType.TEMPLATE:
      return templateSurveyColumns(surveyData);
    case SurveyType.ORGANIZATION:
      return organizationSurveyColumns(surveyData);
    default:
      return [];
  }
};

const useSurveyTable = ({
  surveyType,
  onUseLatestVersionHandler = null,
  onDeleteSurveyHandler = null,
}) => {
  const [columnClicked, setColumnClicked] = useState({});
  const skipPageResetRef = useRef();

  const columns = useMemo(
    () =>
      surveyTableColumns(surveyType, {
        skipPageResetRef,
        columnClicked,
        setColumnClicked,
        onUseLatestVersionHandler,
        onDeleteSurveyHandler,
      }),
    [columnClicked, surveyType, onUseLatestVersionHandler]
  );

  const resetColumnPreferences = useCallback(() => {
    setColumnClicked({});
    skipPageResetRef.current = false;
  }, []);

  return {
    columnClicked,
    skipPageResetRef,
    columns,
    resetColumnPreferences,
  };
};

export default useSurveyTable;
