import firebase from 'firebase.js';

const generateLastOpenedData = (survey, userData) => ({
  ...survey,
  lastOpenedAt: firebase.firestore.Timestamp.now(),
  lastOpenedBy: {
    id: userData.id,
    email: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
    logoUrl: userData.logoUrl,
  },
});

export const updateSurveyLastOpened = ({
  deploymentIndex,
  versionIndex,
  surveyRef,
  userData,
  versions,
  deployments,
}) => {
  if (Number.isInteger(deploymentIndex)) {
    return surveyRef.update({
      deployments: deployments.map((deployment, index) =>
        index === deploymentIndex
          ? generateLastOpenedData(deployment, userData)
          : deployment
      ),
    });
  }

  if (Number.isInteger(versionIndex)) {
    return surveyRef.update({
      versions: versions.map((version, index) =>
        index === versionIndex
          ? generateLastOpenedData(version, userData)
          : version
      ),
    });
  }

  return null;
};
