import { createAction } from 'redux-act';

export const SET_PAGES_WITH_ACTIONS = createAction('SET_PAGES_WITH_ACTIONS');

export const SET_RESPONSE = createAction('SET_RESPONSE');

export const SET_QUESTION_RESPONSES = createAction('SET_QUESTION_RESPONSES');

export const SET_ORGANIZATION_NAME = createAction('SET_ORGANIZATION_NAME');

export const SET_PAGE_INDEX = createAction('SET_PAGE_INDEX');

export const CLEAR_LIVE_SURVEY_STATE = createAction('CLEAR_LIVE_SURVEY_STATE');
