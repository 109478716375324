import {
  CLEAR_LIVE_SURVEY_STATE,
  SET_ORGANIZATION_NAME,
  SET_PAGE_INDEX,
  SET_PAGES_WITH_ACTIONS,
  SET_QUESTION_RESPONSES,
  SET_RESPONSE,
} from 'state/actionCreators/liveSurvey';

export const setPagesWithActions = ({ pagesWithActions }) => {
  return (dispatch) => {
    dispatch(SET_PAGES_WITH_ACTIONS({ pagesWithActions }));
  };
};

export const setQuestionResponses = ({ questionResponses }) => {
  return (dispatch) => {
    dispatch(SET_QUESTION_RESPONSES({ questionResponses }));
  };
};

export const setOrganizationName = ({ organizationName }) => {
  return (dispatch) => {
    dispatch(SET_ORGANIZATION_NAME({ organizationName }));
  };
};

export const setSurveyPageIndex = ({ pageIndex }) => {
  return (dispatch) => {
    dispatch(SET_PAGE_INDEX({ pageIndex }));
  };
};

export const setResponse = ({ response }) => {
  return (dispatch) => {
    dispatch(SET_RESPONSE({ response }));
  };
};

export const clearLiveSurveyState = () => {
  return (dispatch) => {
    dispatch(CLEAR_LIVE_SURVEY_STATE());
  };
};
