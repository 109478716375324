import React from 'react';
import {
  Slider as ReactCompoundSlider,
  Rail,
  Handles,
  Tracks,
  Ticks,
} from 'react-compound-slider';
import PropTypes from 'prop-types';

import SliderRail from 'components/CompoundSlider/SliderRail';
import Handle from 'components/CompoundSlider/Handle';
import Track from 'components/CompoundSlider/Track';
import Tick from 'components/CompoundSlider/Tick';
import { colors } from 'styles';

const defaultSliderStyle = {
  position: 'relative',
  width: '100%',
  padding: '1.5rem 0rem',
};

const defaultTooltipStyle = {
  // Clarify: as width is 78 and margin-left is -39, the tooltip text is centered with text-align center
  'margin-left': '-39px',
  'margin-top': '-37px',
  'text-align': 'center',
  width: '78px',
  text: {
    'background-color': colors.backgroundLightGray,
    'border-radius': '3px',
    padding: '0 3px',
  },
};

const Slider = ({
  mode = 2,
  step = 1,
  domain,
  sliderStyle = defaultSliderStyle,
  trackStyle = {},
  handleStyle = {},
  tooltipStyle = defaultTooltipStyle,
  handleSuffixInLastValue = false,
  handleSuffixInFirstValue = false,
  onUpdate,
  onChange,
  values,
  rootProps = {},
}) => (
  <ReactCompoundSlider
    mode={mode}
    step={step}
    domain={domain}
    rootStyle={sliderStyle}
    onUpdate={onUpdate}
    onChange={onChange}
    values={values}
    rootProps={rootProps}
  >
    <Rail>
      {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
    </Rail>
    <Handles>
      {({ handles, getHandleProps }) => (
        <div className="slider-handles">
          {handles.map((handle) => {
            const isFirstHandle =
              handleSuffixInFirstValue && handle.value === domain[0];

            const isLastHandle =
              handleSuffixInLastValue &&
              handle.value === domain[domain.length - 1];

            return (
              <Handle
                key={handle.id}
                handle={handle}
                domain={domain}
                showValueText={false}
                tooltipStyle={tooltipStyle}
                isFirst={isFirstHandle}
                isLast={isLastHandle}
                getHandleProps={getHandleProps}
                style={handleStyle}
              />
            );
          })}
        </div>
      )}
    </Handles>
    <Tracks right={false}>
      {({ tracks, getTrackProps }) => {
        return (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
                style={trackStyle}
              />
            ))}
          </div>
        );
      }}
    </Tracks>
    <Ticks count={10}>
      {({ ticks }) => (
        <div className="slider-ticks">
          {ticks.map((tick) => {
            const isLastTick =
              handleSuffixInLastValue &&
              tick.value === domain[domain.length - 1];

            const isFirstTick =
              handleSuffixInFirstValue && tick.value === domain[0];

            return (
              <Tick
                key={tick.id}
                tick={tick}
                isLast={isLastTick}
                isFirst={isFirstTick}
                count={ticks.length}
              />
            );
          })}
        </div>
      )}
    </Ticks>
  </ReactCompoundSlider>
);

Slider.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.number,
  step: PropTypes.number,
  handleSuffixInLastValue: PropTypes.bool,
  sliderStyle: PropTypes.shape({}),
  trackStyle: PropTypes.shape({}),
  handleStyle: PropTypes.shape({}),
};

export default Slider;
