import firebase from 'firebase.js';
import { CallableFunctions } from 'utils/enums';

export const deleteSurveyService = async (surveyId) => {
  try {
    const deleteSurvey = firebase
      .functions()
      .httpsCallable(CallableFunctions.DELETE_SURVEY);

    await deleteSurvey({ surveyId });
  } catch (error) {
    console.error(error);

    throw error;
  }
};
