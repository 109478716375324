import { v4 as uuid } from 'uuid';
import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import {
  SURVEYS_CREATE_SURVEY_REPONSE_FAIL,
  SURVEYS_CREATE_SURVEY_REPONSE_INIT,
  SURVEYS_CREATE_SURVEY_REPONSE_SUCCESS,
} from 'state/actionCreators/surveys';
import { firebaseError } from 'utils';
import { responsesCollection } from 'utils/firebase/surveys';
import { QuestionType } from 'utils/surveyBuilder/enums';
import { captureException, saveErrorLog } from 'services/utils';
import { ServiceOperations } from 'utils/enums';

const defaultSliderProps = {
  min: 0,
  max: 100,
  step: 1,
  defaultValue: 0,
  plusToMax: false,
  minusToMin: false,
  value: 0,
};

const singleResponseFieldsDefaultValue = {
  choices: {},
  group: null,
  openAnswer: '',
  openEndedChoices: {},
  openEndedChoiceAnswers: {},
  regionalBreakdown: false,
  required: false,
  value: null,
  visible: true,
  sliderProps: null,
};

const multipleResponseFieldsDefaultValue = {
  ...singleResponseFieldsDefaultValue,
  sliderProps: {
    min: 0,
    max: 100,
    step: 1,
    defaultValue: 0,
    value: 0,
  },
};

const sliderResponseFieldsDefaultValue = {
  ...singleResponseFieldsDefaultValue,
  sliderProps: {
    ...defaultSliderProps,
    plusToMax: false,
    minusToMin: false,
  },
};

const textAreaResponseFieldsDefaultValue = {
  ...singleResponseFieldsDefaultValue,
  sliderProps: {
    defaultSliderProps,
  },
};

const fixChoicesAndOpenEndedChoices = (choices) => {
  const fixedChoices = {};

  Object.entries(choices).forEach(([key, value]) => {
    fixedChoices[key] = value ?? false;
  });

  return fixedChoices;
};

const fixOpenEndedChoiceAnswers = (choices) => {
  const fixedChoices = {};

  Object.entries(choices).forEach(([key, value]) => {
    fixedChoices[key] = value ?? '';
  });

  return fixedChoices;
};

const fixSliderProps = (sliderProps) => {
  const fixedSliderProps = {};

  Object.entries(sliderProps).forEach(([key, value]) => {
    fixedSliderProps[key] = value ?? defaultSliderProps[key] ?? 0;
  });

  return fixedSliderProps;
};

export const createSurveyResponse = ({
  waveId,
  surveyId,
  deploymentId,
  questionResponses,
  demographics,
  organizationName,
}) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_CREATE_SURVEY_REPONSE_INIT());

    const { locale } = getState().preferences;

    const createdAt = firebase.firestore.Timestamp.fromDate(new Date());

    const fixedQuestionResponses = {};

    try {
      Object.entries(questionResponses).forEach(([key, value]) => {
        fixedQuestionResponses[key] = value;

        Object.entries(value).forEach(([responseField, responseFieldValue]) => {
          if (
            fixedQuestionResponses[key].type === QuestionType.SINGLE_RESPONSE
          ) {
            fixedQuestionResponses[key][responseField] =
              responseFieldValue ??
              singleResponseFieldsDefaultValue[responseField] ??
              null;

            if (
              responseField === 'choices' ||
              responseField === 'openEndedChoices'
            ) {
              fixedQuestionResponses[key][responseField] =
                fixChoicesAndOpenEndedChoices(responseFieldValue);
            }

            if (responseField === 'openEndedChoiceAnswers') {
              fixedQuestionResponses[key][responseField] =
                fixOpenEndedChoiceAnswers(responseFieldValue);
            }

            if (
              responseField === 'sliderProps' &&
              responseFieldValue !== null
            ) {
              fixedQuestionResponses[key][responseField] =
                fixSliderProps(responseFieldValue);
            }
          }

          if (
            fixedQuestionResponses[key].type === QuestionType.MULTIPLE_RESPONSE
          ) {
            fixedQuestionResponses[key][responseField] =
              responseFieldValue ??
              multipleResponseFieldsDefaultValue[responseField] ??
              null;

            if (
              responseField === 'choices' ||
              responseField === 'openEndedChoices'
            ) {
              fixedQuestionResponses[key][responseField] =
                fixChoicesAndOpenEndedChoices(responseFieldValue);
            }

            if (responseField === 'openEndedChoiceAnswers') {
              fixedQuestionResponses[key][responseField] =
                fixOpenEndedChoiceAnswers(responseFieldValue);
            }

            if (
              responseField === 'sliderProps' &&
              responseFieldValue !== null
            ) {
              fixedQuestionResponses[key][responseField] =
                fixSliderProps(responseFieldValue);
            }
          }

          if (fixedQuestionResponses[key].type === QuestionType.SLIDER) {
            fixedQuestionResponses[key][responseField] =
              responseFieldValue ??
              sliderResponseFieldsDefaultValue[responseField] ??
              null;

            if (
              responseField === 'choices' ||
              responseField === 'openEndedChoices'
            ) {
              fixedQuestionResponses[key][responseField] =
                fixChoicesAndOpenEndedChoices(responseFieldValue);
            }

            if (responseField === 'openEndedChoiceAnswers') {
              fixedQuestionResponses[key][responseField] =
                fixOpenEndedChoiceAnswers(responseFieldValue);
            }

            if (
              responseField === 'sliderProps' &&
              responseFieldValue !== null
            ) {
              fixedQuestionResponses[key][responseField] =
                fixSliderProps(responseFieldValue);
            }
          }

          if (fixedQuestionResponses[key].type === QuestionType.TEXT_AREA) {
            fixedQuestionResponses[key][responseField] =
              responseFieldValue ??
              textAreaResponseFieldsDefaultValue[responseField] ??
              null;

            if (
              responseField === 'choices' ||
              responseField === 'openEndedChoices'
            ) {
              fixedQuestionResponses[key][responseField] =
                fixChoicesAndOpenEndedChoices(responseFieldValue);
            }

            if (responseField === 'openEndedChoiceAnswers') {
              fixedQuestionResponses[key][responseField] =
                fixOpenEndedChoiceAnswers(responseFieldValue);
            }

            if (
              responseField === 'sliderProps' &&
              responseFieldValue !== null
            ) {
              fixedQuestionResponses[key][responseField] =
                fixSliderProps(responseFieldValue);
            }
          }
        });
      });
    } catch (error) {
      captureException(new Error('Response Fix', { cause: error }));
    }

    const response = {
      userId: uuid(),
      demographics: demographics.length > 0 ? demographics : null,
      groups: null,
      valid: true,
      waveId,
      surveyId,
      deploymentId,
      organizationName,
      questionResponses: fixedQuestionResponses,
      createdAt,
    };

    try {
      await responsesCollection.add(response);
    } catch (error) {
      captureException(new Error('Response Save', { cause: error }));

      const errorMessage = firebaseError(error.code, locale);

      toastr.error('', errorMessage);

      console.error('Create Response in Progress:', error);

      await saveErrorLog(error, {
        operation: ServiceOperations.CREATE_SURVEY_RESPONSE,
        surveyId: surveyId ?? null,
        deploymentId: deploymentId ?? null,
        waveId: waveId ?? null,
      });

      return dispatch(
        SURVEYS_CREATE_SURVEY_REPONSE_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(SURVEYS_CREATE_SURVEY_REPONSE_SUCCESS());
  };
};
