import { CLEAR_LIVE_SURVEY_STATE } from 'state/actionCreators/liveSurvey';
import {
  SURVEYS_FETCH_PREVIEW_INIT,
  SURVEYS_FETCH_PREVIEW_SUCCESS,
} from 'state/actionCreators/surveys';
import { getLocalStorageValue, setLocalStorageValue } from 'utils';

export const fetchPreview = () => {
  return (dispatch) => {
    dispatch(CLEAR_LIVE_SURVEY_STATE());
    dispatch(SURVEYS_FETCH_PREVIEW_INIT());

    const preview = getLocalStorageValue('preview');

    dispatch(SURVEYS_FETCH_PREVIEW_SUCCESS({ preview: preview || null }));
    return setLocalStorageValue('preview', null);
  };
};
