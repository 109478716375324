/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { v4 as uuidv4 } from 'uuid';
import ClipLoader from 'react-spinners/ClipLoader';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';

import { REACT_APP_ALGOLIA_QUESTIONS_CONTENT_INDEX } from 'constants/environment';
import { QuestionType, SurveyType } from 'utils/surveyBuilder/enums';
import { OrderTypes, TagType } from 'utils/enums';
import {
  fetchTags,
  removeTag,
  addTag,
  createTag,
  clearData,
  clearCreateTagState,
} from 'state/actions/tags';
import { fetchDataSets } from 'state/actions/dataSets';
import {
  fetchQuestionChoices,
  clearQuestionChoiceState,
} from 'state/actions/questionChoices';
import {
  getAvailableLanguages,
  questionChoiceIsDuplicated,
} from 'utils/surveyBuilder';
import {
  questionSliderFields,
  sliderQuestionSchema,
} from 'utils/formFields/questionSliderFields';

import { DEFAULT_SLIDER_PROPS } from 'constants/SurveyBuilder';
import Autocomplete from 'components/Algolia/Autocomplete';
import QuestionContent, {
  getItemInputValue,
} from 'components/Algolia/Autocomplete/QuestionContent';

import QuestionTag from './QuestionTag';
import QuestionChoice from './QuestionChoice';

import './QuestionBuilder.scss';
import classes from './QuestionBuilder.module.scss';
import CreateDataSet from './CreateDataSet';

const allQuestionTypeOptions = [
  { label: 'Single Response', value: QuestionType.SINGLE_RESPONSE },
  { label: 'Multiple Response', value: QuestionType.MULTIPLE_RESPONSE },
  { label: 'Text Area', value: QuestionType.TEXT_AREA },
  { label: 'Slider', value: QuestionType.SLIDER },
  { label: 'Dropdown', value: QuestionType.DROPDOWN },
  { label: 'Rank Order', value: QuestionType.RANK_ORDER },
];

const tagTypeOptions = [
  { value: 'Question', label: 'Question' },
  { value: 'Demographic', label: 'Demographic' },
];

const questionTypesWithChoices = {
  [QuestionType.SINGLE_RESPONSE]: QuestionType.SINGLE_RESPONSE,
  [QuestionType.MULTIPLE_RESPONSE]: QuestionType.MULTIPLE_RESPONSE,
  [QuestionType.RANK_ORDER]: QuestionType.RANK_ORDER,
  [QuestionType.DROPDOWN]: QuestionType.DROPDOWN,
};

const getQuestionChoice = ({
  id = null,
  availableLanguages,
  sortOrder = 1,
}) => ({
  id: id || uuidv4(),
  option: getAvailableLanguages(availableLanguages),
  dataSetId: null,
  questionId: null,
  openEnded: false,
  notApplicable: false,
  paramOne: null,
  paramTwo: null,
  visible: true,
  duplicated: false,
  sortOrder,
});

const defaultChoices = (availableLanguages) => [
  getQuestionChoice({ id: '1', availableLanguages, sortOrder: 0 }),
  getQuestionChoice({ id: '2', availableLanguages, sortOrder: 1 }),
  getQuestionChoice({ id: '3', availableLanguages, sortOrder: 2 }),
  getQuestionChoice({ id: '4', availableLanguages, sortOrder: 3 }),
];

const QuestionBuilder = ({
  surveyType,
  organizationName,
  surveyLanguage,
  surveyLanguages,
  loading,
  isUpdating,
  onAddQuestion,
  onUpdateQuestion,
  onCancel,
  previousQuestion,
  deploymentId,
  canDeleteChoice,
}) => {
  const surveyIsTemplate = useMemo(
    () => surveyType === SurveyType.TEMPLATE,
    [surveyType]
  );

  const [questionTypeOptions, setQuestionTypeOptions] = useState(
    allQuestionTypeOptions
  );

  const [tagCreation, setTagCreation] = useState({
    isCreating: false,
    tagName: '',
    tagType: tagTypeOptions[0],
  });

  const [fetchedDataSet, setFetchedDataSet] = useState(false);

  const [question, setQuestion] = useState({
    id: uuidv4(),
    content: getAvailableLanguages(surveyLanguages),
    type: questionTypeOptions[0].value,
    typeSelect: questionTypeOptions[0],
    tags: [],
    selectedTag: null,
    tagWeight: '',
    choices: defaultChoices(surveyLanguages),
    dataSetSelect: null,
    dataSet: {
      id: null,
      displayName: null,
      organization: null,
    },
    required: false,
    choicesOrder: null,
    regionalBreakdown: false,
    sliderProps: DEFAULT_SLIDER_PROPS,
    ...previousQuestion,
  });

  const questionTypeIsSlider = useMemo(
    () => question.type === QuestionType.SLIDER,
    [question.type]
  );

  const questionTypeisNotSingleOrMultipleResponse = useMemo(
    () =>
      question.type !== QuestionType.SINGLE_RESPONSE &&
      question.type !== QuestionType.MULTIPLE_RESPONSE &&
      !questionTypeIsSlider,
    [questionTypeIsSlider, question.type]
  );

  const questionIsTextArea = useMemo(
    () => question.type === QuestionType.TEXT_AREA,
    [question.type]
  );

  const getDesiredTags = useCallback(
    (tag) => {
      const tagTypeIsNotDemographic = tag.type !== TagType.DEMOGRAPHIC;
      const tagTypeIsNotDataset = tag.type !== TagType.DATA_SET;
      const notIncludeTagsAlreadyInQuestion = !previousQuestion?.tags.some(
        ({ id }) => id === tag.id
      );

      if (questionTypeisNotSingleOrMultipleResponse) {
        return (
          tagTypeIsNotDataset &&
          notIncludeTagsAlreadyInQuestion &&
          tagTypeIsNotDemographic
        );
      }

      return tagTypeIsNotDataset && notIncludeTagsAlreadyInQuestion;
    },
    [previousQuestion, questionTypeisNotSingleOrMultipleResponse]
  );

  const {
    tagsOptions,
    dataSetsOptions,
    fetchinDataSetsOptions,
    questionChoices,
    fetchingQuestionChoices,
    fetchingTags,
    successFetchingTags,
    creatingTag,
    successCreatingTag,
    errorCreatingTag,
    fetchedQuestionChoices,
  } = useSelector(
    (state) => ({
      tagsOptions: state.tags.data.filter(getDesiredTags).map((tag) => ({
        label: `${tag.displayName} (${tag.type})`,
        value: tag,
      })),
      dataSetsOptions: state.dataSets.data.map((dataSet) => ({
        label: dataSet.tagDisplayName,
        value: dataSet,
      })),
      fetchinDataSetsOptions: state.dataSets.loading,
      questionChoices: state.questionChoices.data,
      fetchingQuestionChoices: state.questionChoices.loading,
      fetchingTags: state.tags.fetchingTags,
      successFetchingTags: state.tags.successFetchingTags,
      creatingTag: state.tags.creatingTag,
      successCreatingTag: state.tags.successCreatingTag,
      errorCreatingTag: state.tags.errorCreatingTag,
      fetchedQuestionChoices: state.questionChoices.fetchedQuestionChoices,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const organizations = ['global'];

    if (surveyType === SurveyType.ORGANIZATION && organizationName) {
      organizations.push(organizationName);
    }

    dispatch(
      fetchTags({
        filterByActive: true,
        filterByOrganizations: organizations,
      })
    );

    dispatch(
      fetchDataSets({
        filterByActive: true,
        filterByTagType: TagType.DATA_SET,
        filterByOrganizations: organizations,
      })
    );
  }, [dispatch, surveyType, organizationName]);

  useEffect(() => {
    if (fetchedQuestionChoices) {
      setQuestion((prevState) => ({
        ...prevState,
        choices: questionChoices,
      }));
      dispatch(clearQuestionChoiceState());
    }
  }, [fetchedQuestionChoices, dispatch, questionChoices]);

  useEffect(() => {
    if (question.dataSetSelect && !fetchedDataSet) {
      dataSetsOptions.forEach((option) => {
        if (option.value.id === question.dataSetSelect.value.id) {
          setQuestion((prevState) => ({
            ...prevState,
            dataSetSelect: option,
            dataset: {
              id: option.value.id,
              name: option.value.tagDisplayName,
              organization: option.value.tagOrganizationName,
            },
          }));
        }
      });
      setFetchedDataSet(true);
    }
  }, [fetchedDataSet, question.dataSetSelect]);

  useEffect(() => {
    if (successCreatingTag) {
      const organizations = ['global'];

      if (surveyType === SurveyType.ORGANIZATION && organizationName) {
        organizations.push(organizationName);
      }

      dispatch(
        fetchTags({
          filterByActive: true,
          filterByOrganizations: organizations,
        })
      );
    }
  }, [dispatch, successCreatingTag, organizationName, surveyType]);

  useEffect(() => {
    if (errorCreatingTag && question.selectedTag) {
      setQuestion((prevState) => ({ ...prevState, selectedTag: null }));
      dispatch(clearCreateTagState());
    }
  }, [errorCreatingTag, question.selectedTag, dispatch]);

  const clearCreatingTag = useCallback(() => {
    setTagCreation({
      isCreating: false,
      tagName: '',
      tagType: tagTypeOptions[0],
    });
  }, []);

  useEffect(() => {
    if (successCreatingTag && successFetchingTags && question.selectedTag) {
      const { label: tagName, value } = question.selectedTag;
      const selectedTag = tagsOptions.find(({ label }) => label === tagName);
      if (!value && selectedTag) {
        setQuestion((prevState) => {
          const { tags } = prevState;

          const tagValue = selectedTag.value;

          const tag = {
            ...tagValue,
            weight: tagValue.weight ? Number(selectedTag.weight) : null,
          };

          tags.push(tag);
          dispatch(removeTag(tag));

          return {
            ...prevState,
            tags,
            selectedTag: null,
            tagWeight: '',
          };
        });
        dispatch(clearCreateTagState());
      }
      clearCreatingTag();
    }
  }, [
    successCreatingTag,
    successFetchingTags,
    question.selectedTag,
    tagsOptions,
    dispatch,
    clearCreatingTag,
  ]);

  useEffect(() => {
    return () => dispatch(clearData());
  }, [dispatch]);

  useEffect(() => {
    if (question.regionalBreakdown) {
      const singleAndMultipleOptions = allQuestionTypeOptions.filter(
        ({ value }) =>
          value === QuestionType.SINGLE_RESPONSE ||
          value === QuestionType.MULTIPLE_RESPONSE
      );

      setQuestionTypeOptions(singleAndMultipleOptions);
    } else {
      setQuestionTypeOptions(allQuestionTypeOptions);
    }
  }, [question.regionalBreakdown]);

  const onRemoveTagHandler = useCallback(
    (tag) => {
      setQuestion((prevState) => {
        const { tags } = prevState;

        dispatch(addTag(tag));

        return {
          ...prevState,
          tags: tags.filter(({ id }) => id !== tag.id),
        };
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const selectedTagIsDemographic =
      question.selectedTag?.value?.type === TagType.DEMOGRAPHIC;
    const areDemographicsTagsAdded = question.tags.some(
      ({ type }) => type === TagType.DEMOGRAPHIC
    );

    if (questionTypeisNotSingleOrMultipleResponse) {
      if (selectedTagIsDemographic) {
        setQuestion((prevState) => ({ ...prevState, selectedTag: null }));
      }

      if (areDemographicsTagsAdded) {
        question.tags
          .filter(({ type }) => type === TagType.DEMOGRAPHIC)
          .forEach(onRemoveTagHandler);
      }
    }
  }, [
    questionTypeisNotSingleOrMultipleResponse,
    question.selectedTag,
    question.tags,
    onRemoveTagHandler,
  ]);

  const onQuestionChangeHandler = useCallback(
    (value) => {
      const comparedRichText =
        value === previousQuestion?.content?.[surveyLanguage]
          ? previousQuestion?.richText?.[surveyLanguage]
          : `<p>${value}</p>`;

      setQuestion((prevState) => ({
        ...prevState,
        content: {
          ...prevState.content,
          [surveyLanguage]: value,
        },
        richText: {
          ...prevState.richText,
          [surveyLanguage]: comparedRichText,
        },
      }));
    },
    [surveyLanguage]
  );

  const onChangeTypeHandler = useCallback(
    (type) =>
      setQuestion((prevState) => {
        let choices;

        if (!questionTypesWithChoices[type.value]) {
          choices = [];
        } else if (prevState.type === QuestionType.TEXT_AREA) {
          choices = defaultChoices(surveyLanguages);
        } else if (
          type.value !== QuestionType.SINGLE_RESPONSE &&
          type.value !== QuestionType.MULTIPLE_RESPONSE
        ) {
          choices = prevState.choices.map((choice) => ({
            ...choice,
            openEnded: false,
          }));
        } else {
          choices = prevState.choices;
        }

        return {
          ...prevState,
          type: type.value,
          typeSelect: type,
          dataSetSelect:
            type.value === QuestionType.TEXT_AREA
              ? false
              : prevState.dataSetSelect,
          sliderProps:
            type.value === QuestionType.SLIDER
              ? prevState.sliderProps
              : DEFAULT_SLIDER_PROPS,
          dataSet:
            type.value === QuestionType.TEXT_AREA
              ? {
                  id: null,
                  organization: null,
                }
              : prevState.dataSet,
          choices,
        };
      }),
    [surveyLanguages]
  );

  useEffect(() => {
    if (
      questionTypeisNotSingleOrMultipleResponse &&
      question.regionalBreakdown
    ) {
      onChangeTypeHandler(questionTypeOptions[0]);
    }
  }, [
    question.regionalBreakdown,
    questionTypeisNotSingleOrMultipleResponse,
    questionTypeOptions,
    onChangeTypeHandler,
  ]);

  const onChangeTagHandler = useCallback(
    (tag) => setQuestion((prevState) => ({ ...prevState, selectedTag: tag })),
    []
  );

  const onChangeTagWeightHandler = useCallback((weight) => {
    setQuestion((prevState) => ({ ...prevState, tagWeight: weight }));
  }, []);

  const onAddTagHandler = useCallback(
    (e) => {
      e.preventDefault();
      setQuestion((prevState) => {
        const { tags, selectedTag, tagWeight } = prevState;
        const tag = {
          ...selectedTag.value,
          weight: tagWeight ? Number(tagWeight) : null,
        };

        tags.push(tag);
        dispatch(removeTag(tag));
        return {
          ...prevState,
          tags,
          selectedTag: null,
          tagWeight: '',
        };
      });
    },
    [dispatch]
  );

  const onAddChoiceHandler = useCallback(
    (index) =>
      setQuestion((prevState) => {
        const { choices } = prevState;
        choices.splice(index, 0, {
          ...getQuestionChoice({
            availableLanguages: surveyLanguages,
            sortOrder: index,
          }),
          newChoice: true,
        });
        return { ...prevState };
      }),
    [surveyLanguages]
  );

  const onRemoveChoiceHandler = useCallback(
    (index) => {
      const choice = question.choices[index];
      if (!isUpdating || canDeleteChoice(choice.id)) {
        setQuestion((prevState) => {
          const { choices } = prevState;
          choices.splice(index, 1);
          return { ...prevState };
        });
      }
    },
    [canDeleteChoice, isUpdating, question.choices]
  );

  const hasActionsHandler = useCallback(
    (index) => {
      const choice = question.choices[index];
      if (choice && canDeleteChoice(choice.id)) {
        return false;
      }
      return true;
    },
    [canDeleteChoice, question.choices]
  );

  const checkDuplicated = useCallback(() => {
    setQuestion((prevState) => {
      let choices = {};
      const updatedDataItems = prevState.choices.map((item, index) => {
        const otherItems = [...prevState.choices];
        otherItems.splice(index, 1);
        const duplicated =
          (item.option[surveyLanguage] &&
            questionChoiceIsDuplicated(
              item.option[surveyLanguage],
              otherItems,
              surveyLanguage
            )) ||
          false;
        return { ...item, duplicated };
      });

      choices = updatedDataItems;

      return {
        ...prevState,
        choices,
      };
    });
  }, [surveyLanguage]);

  const onChangeChoiceHandler = useCallback(
    ({ choiceIndex, option }) => {
      setQuestion((prevState) => {
        const { choices } = prevState;
        const choice = choices[choiceIndex];
        choices.splice(choiceIndex, 1);
        const duplicated =
          option.trim() &&
          questionChoiceIsDuplicated(option, choices, surveyLanguage);
        choices.splice(choiceIndex, 0, {
          ...choice,
          option: {
            ...choice.option,
            [surveyLanguage]: option,
          },
          duplicated,
        });
        return { ...prevState };
      });
      checkDuplicated();
    },
    [surveyLanguage, checkDuplicated]
  );

  const onLoadFromDataSetHandler = useCallback(
    (select) => {
      if (select) {
        const { id, tagOrganizationName, tagDisplayName } = select.value;
        return setQuestion((prevState) => {
          dispatch(fetchQuestionChoices({ filterByDataSet: id }));
          return {
            ...prevState,
            dataSet: {
              id,
              name: tagDisplayName,
              organization: tagOrganizationName,
            },
            dataSetSelect: select,
          };
        });
      }

      return setQuestion((prevState) => ({
        ...prevState,
        dataSetSelect: null,
        dataSet: {
          id: null,
          organization: null,
        },
        choices: defaultChoices(surveyLanguages),
      }));
    },
    [dispatch, surveyLanguages]
  );

  const onDragEndHandler = useCallback(({ source, destination }) => {
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    setQuestion((prevState) => {
      const { choices } = prevState;

      const [removedChoice] = choices.splice(source.index, 1);
      choices.splice(destination.index, 0, removedChoice);

      return { ...prevState, choices };
    });
  }, []);

  const onCreateQuestionHandler = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e?.preventDefault?.();

    const { content, choices, sliderProps } = question;

    const formatedContent = {};

    Object.entries(content ?? {}).forEach(([languageCode, translation]) => {
      formatedContent[languageCode] = translation.trim();
    });

    const formattedQuestion = {
      ...question,
      content: formatedContent,
      choices: (choices ?? []).map((choice, sortOrder) => {
        const { option } = choice;

        const formatedOption = {};

        Object.entries(option).forEach(([languageCode, translation]) => {
          formatedOption[languageCode] = translation.trim();
        });

        return {
          ...choice,
          option: formatedOption,
          sortOrder,
        };
      }),
    };

    if (question.type === QuestionType.SLIDER) {
      const { min, max, step, defaultValue, plusToMax, minusToMin } =
        sliderProps;
      const formattedSliderProps = {
        min,
        max,
        step,
        defaultValue,
        plusToMax: !!plusToMax,
        minusToMin: !!minusToMin,
      };

      formattedQuestion.sliderProps = formattedSliderProps;
    }

    if (isUpdating) {
      onUpdateQuestion(formattedQuestion);
    } else {
      const formatedQuestionChoices = {
        ...formattedQuestion,
        choices: choices.map((choice) => {
          return {
            ...choice,
            id: uuidv4(),
          };
        }),
      };

      onAddQuestion(formatedQuestionChoices);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: question.sliderProps,
    resolver: yupResolver(
      sliderQuestionSchema({ isQuestionRequired: question.required })
    ),
    mode: 'onSubmit',
  });

  const onRequiredChangeHandler = useCallback(() => {
    setQuestion((prevState) => ({
      ...prevState,
      required: !prevState?.required,
    }));
  }, []);

  const onPlusToMaxChangeHandler = useCallback(() => {
    setQuestion((prevState) => ({
      ...prevState,
      sliderProps: {
        ...prevState.sliderProps,
        plusToMax: !prevState.sliderProps?.plusToMax,
      },
    }));
  }, []);

  const onMinusToMinChangeHandler = useCallback(() => {
    setQuestion((prevState) => ({
      ...prevState,
      sliderProps: {
        ...prevState.sliderProps,
        minusToMin: !prevState.sliderProps?.minusToMin,
      },
    }));
  }, []);

  const onSingleLineTextBoxChangeHandler = useCallback(() => {
    setQuestion((prevState) => ({
      ...prevState,
      singleLine: !prevState.singleLine,
    }));
  }, [question.required]);

  const onRegionalBreakdownChangeHandler = useCallback(() => {
    setQuestion((prevState) => ({
      ...prevState,
      regionalBreakdown: !question.regionalBreakdown,
    }));
  }, [question.regionalBreakdown]);

  const onCreateTagHandler = useCallback(
    (tagName) => {
      const tag = {
        name: tagName,
        global: surveyIsTemplate,
        organization: organizationName,
        active: true,
        type: TagType.QUESTION,
      };

      dispatch(createTag(tag));

      setQuestion((prevState) => ({
        ...prevState,
        selectedTag: {
          label: `${tagName} (${TagType.QUESTION})`,
          value: null,
        },
      }));
    },
    [dispatch, organizationName, surveyIsTemplate]
  );

  const onCreateTagButton = useCallback(() => {
    const tagType =
      tagCreation.tagType.value === TagType.QUESTION
        ? TagType.QUESTION
        : TagType.DEMOGRAPHIC;

    const tag = {
      name: tagCreation.tagName,
      global: surveyIsTemplate,
      organization: organizationName,
      active: true,
      type: tagType,
    };

    dispatch(createTag(tag));

    setQuestion((prevState) => ({
      ...prevState,
      selectedTag: {
        label: `${tagCreation.tagName} (${tagType})`,
        value: null,
      },
    }));
  }, [dispatch, organizationName, surveyIsTemplate, tagCreation]);

  const onChangeVisibilityHandler = useCallback(
    (choiceIndex) =>
      setQuestion((prevState) => {
        const { choices } = prevState;
        const prevChoice = choices[choiceIndex];
        const choice = {
          ...prevChoice,
          visible: !prevChoice.visible,
        };
        choices.splice(choiceIndex, 1, choice);
        return { ...prevState };
      }),
    []
  );

  const onChangeOpenEndedHandler = useCallback(
    (choiceIndex) =>
      setQuestion((prevState) => {
        const { choices } = prevState;
        const prevChoice = choices[choiceIndex];
        const choice = {
          ...prevChoice,
          openEnded: !prevChoice.openEnded,
        };
        choices.splice(choiceIndex, 1, choice);
        return { ...prevState };
      }),
    []
  );

  const onChangeNotApplicableHandler = useCallback(
    (choiceIndex) =>
      setQuestion((prevState) => {
        const { choices } = prevState;
        const prevChoice = choices[choiceIndex];
        const choice = {
          ...prevChoice,
          notApplicable: !prevChoice.notApplicable,
        };
        choices.splice(choiceIndex, 1, choice);
        return { ...prevState };
      }),
    []
  );

  const onChoicesOrderChangeHandler = useCallback((order) => {
    setQuestion((prevState) => ({
      ...prevState,
      choicesOrder: prevState?.choicesOrder === order ? null : order,
    }));
  }, []);

  const onTagTypeChangeHandler = useCallback(
    (tagType) =>
      setTagCreation((prevState) => ({
        ...prevState,
        tagType,
      })),
    []
  );

  const onChangeCreatingTag = useCallback(
    () =>
      setTagCreation((prevState) => ({
        ...prevState,
        isCreating: true,
      })),
    []
  );

  const onChangeTagName = useCallback(
    (tagName) =>
      setTagCreation((prevState) => ({
        ...prevState,
        tagName,
      })),
    []
  );

  const canSubmit =
    question.content[surveyLanguage].trim() &&
    question.choices.every(
      ({ option, duplicated }) => option[surveyLanguage].trim() && !duplicated
    );

  const canCreateTag = useMemo(
    () =>
      tagCreation.tagName &&
      tagCreation.tagType &&
      (surveyIsTemplate || organizationName),
    [tagCreation, organizationName, surveyIsTemplate]
  );

  const getCreateTagTooltip = useCallback(() => {
    let tooltip = '';

    if (!surveyIsTemplate && !organizationName) {
      tooltip = 'Select an organization to create a Tag';
    }

    return tooltip;
  }, [organizationName, surveyIsTemplate]);

  const onChangeSliderConfig = ({ name, value }) => {
    if (name) {
      const formattedValue = value !== '' ? Number(value) : null;

      setQuestion((prevState) => ({
        ...prevState,
        sliderProps: {
          ...prevState.sliderProps,
          [name]: formattedValue,
        },
      }));
    }
  };

  const disableKeyTypingsForSliderInputs = (event) => {
    if (event.key === 'e' || event.key === '+') {
      event.preventDefault();
    }
  };

  const [autocompleteQuestion, setAutocompleteQuestion] = useState(
    question.content[surveyLanguage]
  );

  useEffect(() => {
    onQuestionChangeHandler(autocompleteQuestion);
  }, [autocompleteQuestion, onQuestionChangeHandler]);

  const initialState = useMemo(
    () => (previousQuestion ? previousQuestion.content[surveyLanguage] : ''),
    [previousQuestion, surveyLanguage]
  );

  const selectedTagIsConstruct = useMemo(
    () => question.selectedTag?.value?.type === TagType.CONSTRUCT,
    [question.selectedTag]
  );

  const tagWeightIsFilled = useMemo(
    () => (selectedTagIsConstruct ? !!question.tagWeight : true),
    [selectedTagIsConstruct, question.tagWeight]
  );

  const canAddTag = useMemo(
    () =>
      question.selectedTag &&
      !fetchingTags &&
      !creatingTag &&
      tagWeightIsFilled,
    [question.selectedTag, fetchingTags, creatingTag, tagWeightIsFilled]
  );

  const onCancelEditQuestion = useCallback(() => {
    onCancel(question.id);
  }, [onCancel, question.id]);

  const onSubmitCreateQuestionForm = useCallback(
    (event) => {
      event.preventDefault();

      if (canSubmit) {
        if (questionTypeIsSlider) {
          handleSubmit(onCreateQuestionHandler)();
        } else {
          onCreateQuestionHandler();
        }
      }
    },
    [canSubmit, handleSubmit, onCreateQuestionHandler, questionTypeIsSlider]
  );

  return (
    <DragDropContext onDragEnd={onDragEndHandler}>
      <div className="modal is-active question-modal">
        <div id="background" className="modal-background" />
        <div className="modal-content question-modal__content">
          <form
            id="create-question-form"
            onSubmit={onSubmitCreateQuestionForm}
          />
          <form id="add-tag-form" onSubmit={onAddTagHandler} />
          <header className="modal-card-head">
            <p className="modal-card-title">
              {isUpdating ? 'Update Question' : 'Add Question'}
            </p>
            <button
              id="secondary-cancel-button"
              type="button"
              className="delete"
              aria-label="close"
              disabled={loading}
              onClick={onCancelEditQuestion}
            />
          </header>
          <section className="modal-card-body question-modal__body">
            <div className="field is-grouped is-grouped-multiline">
              <div className="control is-expanded">
                <div className="field">
                  <div className="control">
                    <Autocomplete
                      initialState={initialState}
                      setAutocompleteQuestion={setAutocompleteQuestion}
                      placeholder="Enter your question"
                      sourceId="questionsContent"
                      indexName={REACT_APP_ALGOLIA_QUESTIONS_CONTENT_INDEX}
                      ItemComponent={QuestionContent}
                      getItemInputValue={getItemInputValue}
                    />
                  </div>
                </div>
              </div>
              <div className="control question-type__type-select">
                <Select
                  placeholder="Question type"
                  isSearchable={false}
                  value={question.typeSelect}
                  options={questionTypeOptions}
                  onChange={onChangeTypeHandler}
                  isDisabled={deploymentId}
                />
              </div>
            </div>
            <label
              htmlFor="required-checkbox"
              className="b-checkbox checkbox has-text-weight-bold"
            >
              Required
              <input
                id="required-checkbox"
                type="checkbox"
                name="required"
                onChange={onRequiredChangeHandler}
                checked={question.required}
              />
              <span
                className={classNames('check is-primary', classes.checkbox)}
              />
            </label>
            {questionTypeIsSlider && (
              <label
                htmlFor="add-plus-to-max-checkbox"
                className="b-checkbox checkbox has-text-weight-bold"
              >
                Add + To Max
                <input
                  id="add-plus-to-max-checkbox"
                  type="checkbox"
                  name="plusToMax"
                  onChange={onPlusToMaxChangeHandler}
                  checked={question.sliderProps.plusToMax}
                />
                <span
                  className={classNames('check is-primary', classes.checkbox)}
                />
              </label>
            )}
            {questionTypeIsSlider && (
              <label
                htmlFor="add-minus-to-min-checkbox"
                className="b-checkbox checkbox has-text-weight-bold"
              >
                Add &#60; To Min
                <input
                  id="add-minus-to-min-checkbox"
                  type="checkbox"
                  name="minusToMin"
                  onChange={onMinusToMinChangeHandler}
                  checked={question.sliderProps.minusToMin}
                />
                <span
                  className={classNames('check is-primary', classes.checkbox)}
                />
              </label>
            )}
            {questionIsTextArea && (
              <label
                htmlFor="single-line-input-checkbox"
                className="b-checkbox checkbox has-text-weight-bold"
              >
                Single Line
                <input
                  id="single-line-input-checkbox"
                  type="checkbox"
                  name="singleLine"
                  onChange={onSingleLineTextBoxChangeHandler}
                  checked={!!question.singleLine}
                />
                <span
                  className={classNames('check is-primary', classes.checkbox)}
                />
              </label>
            )}
            {!questionTypeisNotSingleOrMultipleResponse &&
              !questionTypeIsSlider && (
                <label
                  htmlFor="regional-breakdown-checkbox"
                  className="b-checkbox checkbox has-text-weight-bold"
                >
                  Regional Breakdown
                  <input
                    id="regional-breakdown-checkbox"
                    type="checkbox"
                    name="regionalBreakdown"
                    onChange={onRegionalBreakdownChangeHandler}
                    checked={question.regionalBreakdown}
                  />
                  <span
                    className={classNames('check is-primary', classes.checkbox)}
                  />
                </label>
              )}
            <hr />
            <h2 className="subtitle">Tags</h2>
            <div
              className={classNames(
                'control is-expanded field',
                classes['hidden-desktop']
              )}
            >
              {surveyType === SurveyType.ORGANIZATION && !organizationName && (
                <Select
                  placeholder="Tags"
                  value={question.selectedTag}
                  isLoading={fetchingTags}
                  isSearchable
                  options={tagsOptions}
                  onChange={onChangeTagHandler}
                />
              )}
              {(surveyIsTemplate || organizationName) && (
                <CreatableSelect
                  placeholder="Tags"
                  value={question.selectedTag}
                  isLoading={fetchingTags || creatingTag}
                  isSearchable
                  options={tagsOptions}
                  onChange={onChangeTagHandler}
                  onCreateOption={onCreateTagHandler}
                />
              )}
            </div>
            <div className="field is-grouped is-grouped-multiline">
              <div
                className={classNames(
                  'control is-expanded',
                  classes['hidden-mobile']
                )}
              >
                {surveyType === SurveyType.ORGANIZATION &&
                  !organizationName && (
                    <Select
                      placeholder="Tags"
                      value={question.selectedTag}
                      isLoading={fetchingTags}
                      isSearchable
                      options={tagsOptions}
                      onChange={onChangeTagHandler}
                    />
                  )}
                {(surveyIsTemplate || organizationName) && (
                  <Select
                    placeholder="Tags"
                    value={question.selectedTag}
                    isLoading={fetchingTags || creatingTag}
                    isSearchable
                    options={tagsOptions}
                    onChange={onChangeTagHandler}
                  />
                )}
              </div>
              <div className={classNames('control', classes['tag-weight'])}>
                <input
                  className="input"
                  disabled={!selectedTagIsConstruct}
                  type="number"
                  value={question.tagWeight}
                  placeholder="Tag Weight"
                  onChange={(e) => onChangeTagWeightHandler(e.target.value)}
                  form="add-tag-form"
                />
              </div>
              <div className="control">
                <button
                  type="submit"
                  className="button is-info"
                  disabled={!canAddTag}
                  form="add-tag-form"
                >
                  Add
                </button>
              </div>
            </div>
            {!tagCreation.isCreating && (
              <span data-tip={getCreateTagTooltip()}>
                <button
                  id="create-dataset-first-button"
                  className={classNames('button', classes['create-tag-button'])}
                  disabled={!surveyIsTemplate && !organizationName}
                  onClick={onChangeCreatingTag}
                >
                  <span className="icon">
                    <i className="mdi mdi-plus-circle-outline" />
                  </span>
                  <span>Create Tag</span>
                </button>
                <ReactTooltip />
              </span>
            )}
            {tagCreation.isCreating && (
              <>
                <div className="field is-grouped is-grouped-multiline">
                  <div
                    className={classNames(
                      'control is-expanded',
                      classes['create-tag-fields']
                    )}
                  >
                    <span
                      className={classNames(
                        classes['create-tag-text'],
                        classes['tag-name']
                      )}
                    >
                      Tag name
                    </span>
                    <input
                      className="input"
                      type="text"
                      placeholder="Enter the tag name"
                      required
                      onChange={(e) => onChangeTagName(e.target.value)}
                      form="create-question-form"
                    />
                  </div>
                  <div
                    className={classNames(
                      'control is-expanded',
                      classes['create-tag-fields']
                    )}
                  >
                    <span className={classes['create-tag-text']}>Tag type</span>
                    <Select
                      placeholder="Tag type"
                      isSearchable={false}
                      value={tagCreation.tagType}
                      options={tagTypeOptions}
                      onChange={onTagTypeChangeHandler}
                      className={classes['tag-types-select']}
                    />
                  </div>
                </div>
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      className="button is-primary"
                      type="button"
                      disabled={!canCreateTag || creatingTag}
                      onClick={onCreateTagButton}
                    >
                      <span className={classes['create-tag-button-text']}>
                        {creatingTag ? (
                          <ClipLoader color="white" size={20} />
                        ) : (
                          'Create'
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="control">
                    <button
                      id="create-dataset-cancel-button"
                      className="button is-light"
                      type="button"
                      onClick={clearCreatingTag}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className="box">
              <div className="field is-grouped is-grouped-multiline">
                {question.tags.map((tag) => (
                  <div
                    key={tag.id}
                    className={classNames('control', classes.tags)}
                  >
                    <QuestionTag tag={tag} onRemove={onRemoveTagHandler} />
                  </div>
                ))}
              </div>
            </div>
            {questionTypesWithChoices[question.type] && (
              <>
                <hr />
                <div className="field is-grouped is-grouped-multiline">
                  <div className="control question-choices__title">
                    <h2 className="subtitle">Choices</h2>
                  </div>
                  <div className="control question-choices__data-set-select">
                    <Select
                      placeholder="Load from Data Set"
                      value={question.dataSetSelect}
                      isSearchable
                      isClearable
                      options={dataSetsOptions}
                      isLoading={fetchinDataSetsOptions}
                      onChange={onLoadFromDataSetHandler}
                      isDisabled={deploymentId}
                      menuPortalTarget={document.body} // to avoid cut dropdown
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }), // to avoid cut dropdown
                      }}
                      menuPosition="fixed"
                    />
                  </div>
                  <div
                    className={classNames(
                      'control',
                      classes['choice-order-container']
                    )}
                  >
                    <span
                      className={classNames('icon', classes['cursor-pointer'], {
                        [classes['choice-order-selected']]:
                          question.choicesOrder === OrderTypes.RANDOM,
                      })}
                      data-tip="Random order"
                      data-for="question-order"
                      onClick={() =>
                        onChoicesOrderChangeHandler(OrderTypes.RANDOM)
                      }
                    >
                      <i className="mdi mdi-shuffle-variant" />
                    </span>
                    <span
                      className={classNames('icon', classes['cursor-pointer'], {
                        [classes['choice-order-selected']]:
                          question.choicesOrder === OrderTypes.ASC,
                      })}
                      data-tip="Alphabetical asc order"
                      data-for="question-order"
                      onClick={() =>
                        onChoicesOrderChangeHandler(OrderTypes.ASC)
                      }
                    >
                      <i className="mdi mdi-sort-alphabetical-ascending" />
                    </span>
                    <span
                      className={classNames('icon', classes['cursor-pointer'], {
                        [classes['choice-order-selected']]:
                          question.choicesOrder === OrderTypes.DESC,
                      })}
                      data-tip="Alphabetical desc order"
                      data-for="question-order"
                      onClick={() =>
                        onChoicesOrderChangeHandler(OrderTypes.DESC)
                      }
                    >
                      <i className="mdi mdi-sort-alphabetical-descending" />
                    </span>
                    <ReactTooltip id="question-order" />
                  </div>
                </div>
                <div className={classes['create-dataset']}>
                  <CreateDataSet
                    organizationName={organizationName}
                    surveyType={surveyType}
                    choices={question.choices}
                    surveyLanguage={surveyLanguage}
                    onLoadFromDataSetHandler={onLoadFromDataSetHandler}
                    dataSetSelected={question.dataSetSelect}
                    deploymentId={deploymentId}
                  />
                </div>
                {question.dataSetSelect && fetchingQuestionChoices ? (
                  <ClipLoader />
                ) : (
                  <Droppable droppableId="choices">
                    {(droppable, droppableState) => (
                      <div
                        ref={droppable.innerRef}
                        {...droppable.droppableProps}
                      >
                        {question.choices.map(
                          (
                            {
                              id,
                              option,
                              visible,
                              duplicated,
                              openEnded,
                              notApplicable,
                              newChoice,
                            },
                            index
                          ) => (
                            <Draggable key={id} draggableId={id} index={index}>
                              {(draggable, draggableState) => (
                                <QuestionChoice
                                  choiceIndex={index}
                                  duplicated={duplicated}
                                  choiceValue={option[surveyLanguage]}
                                  visible={visible}
                                  openEnded={openEnded}
                                  notApplicable={notApplicable}
                                  questionType={question.type}
                                  onAddChoice={onAddChoiceHandler}
                                  onChangeChoice={onChangeChoiceHandler}
                                  onDeleteChoice={onRemoveChoiceHandler}
                                  onChangeVisibility={onChangeVisibilityHandler}
                                  onChangeOpenEnded={onChangeOpenEndedHandler}
                                  onChangeNotApplicable={
                                    onChangeNotApplicableHandler
                                  }
                                  readOnly={!!question.dataSetSelect}
                                  canBeRemoved={question.choices.length > 1}
                                  userIsDragging={droppableState.isDraggingOver}
                                  isBeingDragged={draggableState.isDragging}
                                  dragProps={draggable}
                                  deploymentId={deploymentId}
                                  hasActions={hasActionsHandler(index)}
                                  newChoice={newChoice}
                                />
                              )}
                            </Draggable>
                          )
                        )}
                        {droppable.placeholder}
                      </div>
                    )}
                  </Droppable>
                )}
              </>
            )}
            {questionTypeIsSlider && (
              <div className={classes['slider-inputs-container']}>
                {questionSliderFields.map(({ name, label, type, required }) => {
                  const { onChange, onBlur, ...rest } = register(name, {
                    required,
                  });

                  const onChangeSliderInput = (e) => {
                    const { value } = e.target;

                    onChangeSliderConfig({ name, value });
                    onChange(e);
                  };

                  return (
                    <label
                      key={name}
                      htmlFor={name}
                      className={classNames(classes['inputs-label'], {
                        [classes['text-error']]: !!errors[name],
                      })}
                    >
                      {label}
                      <input
                        className={classNames('input', {
                          [classes.error]: !!errors[name],
                        })}
                        id={name}
                        type={type}
                        required={required}
                        disabled={deploymentId}
                        value={question.sliderProps?.[name] ?? ''}
                        onKeyDown={disableKeyTypingsForSliderInputs}
                        onBlur={onBlur}
                        onChange={onChangeSliderInput}
                        {...rest}
                      />
                      <span
                        className={classNames(
                          classes['error-message'],
                          classes['text-error']
                        )}
                      >
                        {errors[name]?.message}
                      </span>
                    </label>
                  );
                })}
              </div>
            )}
          </section>
          <footer className="modal-card-foot">
            <button
              id="submit-button"
              type="submit"
              className={classNames('button', 'is-success', {
                'is-loading': loading,
              })}
              disabled={!canSubmit}
              form="create-question-form"
            >
              {isUpdating ? 'Update' : 'Add'}
            </button>
            <button
              id="primary-cancel-button"
              type="button"
              className="button"
              disabled={loading}
              onClick={onCancelEditQuestion}
            >
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </DragDropContext>
  );
};

QuestionBuilder.propTypes = {
  surveyLanguage: PropTypes.string.isRequired,
  surveyLanguages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onAddQuestion: PropTypes.func.isRequired,
  onUpdateQuestion: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  surveyType: PropTypes.oneOf([SurveyType.ORGANIZATION, SurveyType.TEMPLATE])
    .isRequired,
  organizationName: PropTypes.string,
  isUpdating: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  previousQuestion: PropTypes.shape({
    content: PropTypes.object.isRequired,
    type: PropTypes.oneOf([
      QuestionType.MULTIPLE_RESPONSE,
      QuestionType.SINGLE_RESPONSE,
      QuestionType.TEXT_AREA,
      QuestionType.DROPDOWN,
    ]).isRequired,
    typeSelect: PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({ displayName: PropTypes.string.isRequired })
    ).isRequired,
    choices: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired })
    ).isRequired,
    dataSetSelect: PropTypes.shape({
      value: PropTypes.object.isRequired,
      label: PropTypes.string.isRequired,
    }),
    dataSetId: PropTypes.string,
  }),
  canDeleteChoice: PropTypes.func.isRequired,
};

QuestionBuilder.defaultProps = {
  loading: false,
  organizationName: '',
  previousQuestion: null,
};

export default QuestionBuilder;
