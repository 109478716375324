const getDeploymentData = ({
  name,
  deploymentId,
  surveyVersionId,
  versionName,
  pages,
  pagesOrder,
  sections,
  waveId,
  waveName,
  waveIndex,
  waveStatus,
  organizationDisplaySiteName,
  availableLanguages,
  defaultLanguage,
  isCondensed,
  languageCompletion,
  disableDeselect,
  sampleSurvey,
  url,
  surveyId,
  organizationId,
  organizationName,
  startDate,
  endDate,
  versionTitle,
  items,
  createdAt,
  firstName,
  lastName,
  actions,
}) => {
  const versionTitleParsed = {};

  Object.entries(versionTitle).forEach(([language, title]) => {
    versionTitleParsed[language] = title.trim();
  });

  const deployment = {
    actions,
    url: url.trim(),
    surveyName: name.trim(),
    timeProcessed: null,
    organizationId,
    organizationName,
    organizationDisplaySiteName,
    surveyId,
    versionName: versionName.trim(),
    versionId: surveyVersionId,
    waveName: waveName || null,
    waveIndex,
    waveId,
    waveStatus,
    startDate,
    endDate,
    title: versionTitleParsed,
    pages,
    pagesOrder,
    sections,
    items,
    createdAt,
    createdBy: `${firstName} ${lastName}`,
    totalResponses: 0,
    unprocessedResponses: 0,
    lastTimeProcessed: null,
    availableLanguages,
    defaultLanguage,
    isCondensed,
    languageCompletion,
    disableDeselect,
    sampleSurvey,
    hits: {},
  };

  const newDeployment = {
    id: deploymentId,
    url: deployment.url,
    versionName: deployment.versionName,
    versionId: deployment.versionId,
    waveName: deployment.waveName,
    waveIndex: deployment.waveIndex,
    waveId: deployment.waveId,
    waveStatus: deployment.waveStatus,
    startDate: deployment.startDate,
    endDate: deployment.endDate,
    createdBy: deployment.createdBy,
    createdAt: deployment.createdAt,
    totalResponses: deployment.totalResponses,
    unprocessedResponses: deployment.unprocessedResponses,
    lastTimeProcessed: deployment.lastTimeProcessed,
  };

  return { deployment, newDeployment };
};

export default getDeploymentData;
