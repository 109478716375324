/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getSection,
  isElementInActions,
  isNotItemInActions,
} from 'utils/surveyBuilder';
import { ModalType } from 'utils/enums';
import { wait } from 'utils';
import { useQuery } from 'hooks';
import Card from 'components/Card';
import Input, { Type as InputType } from 'components/Input';
import Button from 'components/Button';
import Collapse from 'components/Collapse';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import Dropdown from 'components/Navigation/Dropdown';
import Sections from 'components/SurveyBuilder/Sections';

import classes from './Page.module.scss';

const Page = ({
  survey,
  setSurvey,
  pageId,
  pageIndex,
  title,
  visible,
  onAddPage,
  sections,
  onOpenModal,
  deploymentId,
}) => {
  const query = useQuery();
  const history = useHistory();
  const { pathname } = useLocation();
  const pageRef = useRef(null);

  const currentPage = Number(query.get('page'));

  const selectedPage = currentPage === 0 ? currentPage : currentPage - 1;

  const onPageVisibilityChangeHandler = useCallback(
    (newVisible) =>
      setSurvey((prevState) => {
        const { pages, sections: stateSections, items } = prevState;
        const page = pages[pageId];

        page.visible = newVisible;

        return { ...prevState, pages, sections: stateSections, items };
      }),
    [setSurvey, pageId]
  );

  const onCollapseAllSectionsHandler = useCallback(
    (collapsed) =>
      setSurvey((prevState) => {
        const { pages, sections: stateSections } = prevState;
        const page = pages[pageId];

        page.sectionIds.forEach((sectionId) => {
          const section = stateSections[sectionId];
          stateSections[sectionId] = { ...section, collapsed };
        });

        return { ...prevState, pages, sections: stateSections };
      }),
    [setSurvey, pageId]
  );

  const onAddSectionHandler = useCallback(
    (newSectionIndex = 0) =>
      setSurvey((prevState) => {
        const {
          pages,
          sections: stateSections,
          availableLanguages,
        } = prevState;

        const newSection = getSection({
          availableLanguages,
        });

        const page = pages[pageId];

        const pageSectionIds = page.sectionIds.toSpliced(
          newSectionIndex,
          0,
          newSection.id
        );

        return {
          ...prevState,
          pages: {
            ...pages,
            [pageId]: { ...page, sectionIds: pageSectionIds },
          },
          sections: { ...stateSections, [newSection.id]: newSection },
        };
      }),
    [setSurvey, pageId]
  );

  const [collapsed, setCollapsed] = useState(true);

  const onTitleChangeHandler = useCallback(
    (e) => {
      const { value } = e.target;

      setSurvey((prevState) => {
        const { pages, selectedLanguage } = prevState;
        const page = pages[pageId];

        return {
          ...prevState,
          pages: {
            ...pages,
            [pageId]: {
              ...page,
              title: {
                ...page.title,
                [selectedLanguage]: value,
              },
            },
          },
        };
      });
    },
    [setSurvey, pageId]
  );

  const changePageHandler = useCallback(
    (pageToGo) => {
      history.replace(`${pathname}?page=${pageToGo}`);
    },
    [history, pathname]
  );

  const onCollapseHandler = useCallback(() => {
    setCollapsed((prevState) => !prevState);

    changePageHandler(pageIndex + 1);
  }, [pageIndex, changePageHandler]);

  const triggerScroll = useCallback(async () => {
    await wait(1);

    // eslint-disable-next-line no-unused-expressions
    pageRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [pageRef]);

  useEffect(() => {
    if (selectedPage !== pageIndex) {
      return setCollapsed(true);
    }

    setCollapsed(false);
    return changePageHandler(pageIndex + 1);
  }, [pageIndex, selectedPage]);

  useEffect(() => {
    if (!collapsed && selectedPage === pageIndex) {
      triggerScroll();
    }
  }, [collapsed, pageId, pageIndex, selectedPage, triggerScroll]);

  const onClickFooterHandler = useCallback(() => {
    changePageHandler(pageIndex + 2);
    onAddPage(pageIndex + 1);
  }, [changePageHandler, onAddPage, pageIndex]);

  const canDeleteSection = useCallback(
    (sectionId) => {
      const canDelete = survey?.actions?.every(({ actions, conditions }) => {
        const section = survey?.sections[sectionId];
        const actionsHaveSection = isElementInActions(actions, sectionId);
        const actionsHaveItemOfSection = section.itemIds.every((itemId) => {
          return isNotItemInActions({ actions, conditions }, itemId);
        });
        return actionsHaveSection && actionsHaveItemOfSection;
      });
      return canDelete;
    },
    [survey]
  );

  const canDeletePage = useMemo(() => {
    const canDelete = survey?.actions?.every(({ actions }) => {
      const actionsHavePage = isElementInActions(actions, pageId);
      const page = survey.pages[pageId];
      const actionsHaveElementsOfPage = page.sectionIds.every((sectionId) => {
        return canDeleteSection(sectionId);
      });
      return actionsHavePage && actionsHaveElementsOfPage;
    });
    return canDelete;
  }, [survey, canDeleteSection, pageId]);

  const onDeletePageHandler = useCallback(() => {
    if (canDeletePage) {
      onOpenModal(ModalType.DELETE_SURVEY_PAGE, { pageIndex });
    } else {
      onOpenModal(ModalType.CANNOT_DELETE_SURVEY_PAGE);
    }
  }, [canDeletePage, onOpenModal, pageIndex]);

  const onVisibilityChangeHandler = useCallback(
    () => onPageVisibilityChangeHandler(!visible),
    [onPageVisibilityChangeHandler, visible]
  );

  return (
    <Card visible={visible}>
      <CardHeader>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <div className={classes['page-count']}>{`${pageIndex + 1}.`}</div>
              <Input
                id="title"
                type={InputType.Text}
                placeHolder="Page title"
                value={title}
                onChange={onTitleChangeHandler}
              />
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="field is-grouped is-grouped-multiline">
                <div className="control">
                  <Button
                    id="change-visibility"
                    icon={classNames('mdi', {
                      'mdi-eye': visible,
                      'mdi-eye-off': !visible,
                    })}
                    onClick={onVisibilityChangeHandler}
                  />
                </div>
                <div className="control">
                  <Dropdown id="page-actions" placeHolder="Page Actions">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() =>
                        onAddSectionHandler(pageIndex, { visible })
                      }
                    >
                      Add Section
                    </button>
                    <button
                      type="button"
                      className={classNames('dropdown-item', {
                        [classes.hide]: deploymentId,
                      })}
                      onClick={onDeletePageHandler}
                    >
                      Remove Page
                    </button>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        const collapse = true;
                        onCollapseAllSectionsHandler(collapse);
                      }}
                    >
                      Collapse Sections
                    </button>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        const collapse = false;
                        onCollapseAllSectionsHandler(collapse);
                      }}
                    >
                      Uncollapse Sections
                    </button>
                  </Dropdown>
                </div>
                <div className="control">
                  <Collapse
                    id="collapse-page"
                    collapsed={collapsed}
                    onCollapse={onCollapseHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody innerRef={pageRef} collapsed={collapsed}>
        <Sections
          survey={survey}
          setSurvey={setSurvey}
          pageId={pageId}
          pageIndex={pageIndex}
          sections={sections}
          pageIsVisible={visible}
          onAddSection={onAddSectionHandler}
          canDeleteSection={canDeleteSection}
          onOpenModal={onOpenModal}
          deploymentId={deploymentId}
        />
      </CardBody>
      <CardFooter displayText="New Page" onClick={onClickFooterHandler} />
    </Card>
  );
};

Page.propTypes = {
  pageId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onAddPage: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  survey: PropTypes.shape({}).isRequired,
  setSurvey: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deploymentId: PropTypes.string,
};

export default Page;
